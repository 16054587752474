import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Admin from './Admin'
import Login from './Login'
import PrivateRoute from './PrivateRoute'

const AllRoutes = () => {
  return (
    <Routes>
             {/* sign up  */}
        <Route path="/" element={<Login/>} />
            <Route path="/admin/*" element={
            <PrivateRoute>
            <Admin/>
            </PrivateRoute>
            }/>
    </Routes>
  )
}

export default AllRoutes