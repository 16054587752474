import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import 'react-phone-number-input/style.css'

const EditOutlet = () => {
  const { id } = useParams();
  let url = process.env.REACT_APP_DEV_URL;
  const [outlet, setOutlet] = useState({});
  const navigate = useNavigate();
  const [city,setCity]=useState([])
  const toast = useToast();

  const handleChange = (e) => {
    let { name, value } = e.target;
    setOutlet({ ...outlet, [name]: value });
  };



  const getData = async () => {
    try {
      let data = await fetch(`${url}/outlet/${id}`);
      data = await data.json();
      console.log(data.data);
      setOutlet(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdate = async () => {
    let dup={...outlet}
    try {
      let data = await fetch(`${url}/outlet/edit/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dup),
      });
      data = await data.json();
      console.log(data);
      toast({
        title: "Outlet has been successfully updated",
        description: data.msg,
        status: "success",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
      navigate("/admin/outlet");
    } catch (error) {
      console.log(error);
      toast({
        title: error.message,
        description: error,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const getCategory=async()=>{
    try {
        let data=await fetch(`${url}/city`)
        data=await data.json()
        console.log(data)
        setCity(data.data)
    } catch (error) {
        console.log(error);
    }
}

  useEffect(() => {
    getData();
    getCategory()
  }, []);
  return (
    <Box p="4">
      <Flex
        justifyContent={"space-around"}
        gap="40px"
        flexDirection={["column", "column", "column", "row", "row"]}
      >
        <Box
          backgroundColor={"white"}
          w={["100%", "100%", "100%", "100%", "100%"]}
          boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          padding={"20px"}
          borderRadius={"20px"}
          border={"1px solid #f9a526"}
        >
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Name</FormLabel>
            <Input
              variant={"flushed"}
              type="text"
              name="name"
              value={outlet?.name}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Landmark</FormLabel>
            <Input
              variant={"flushed"}
              type="text"
              name="landmark"
              value={outlet?.landmark}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl>
            <FormLabel color={"#f9a526"}>Category</FormLabel>
            <select
              style={{
                width: "200px",
                padding: "10px",
                border: "1px solid #f9a526",
                borderRadius: "20px",
              }}
              onChange={(e) =>
                setOutlet({ ...outlet, city: e.target.value })
              }
            >
              <option
                value={outlet?.city?._id}
              >
                {outlet?.city?.name}
              </option>
              {city &&
                city?.map((e) => <option style={{
                  width: "200px",
                  padding: "10px",
                  margin:"10px",
                  border: "1px solid #f9a526",
                  borderRadius: "20px",
                }} value={e?._id}>{e.name}</option>)}
            </select>
         
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Location</FormLabel>
            <Input
              variant={"flushed"}
              type="text"
              name="location"
              value={outlet?.location}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Location Url</FormLabel>
            <Input
              variant={"flushed"}
              type="text"
              name="location_link"
              value={outlet?.location_link}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>contact</FormLabel>
            <Input
            variant={"flushed"}
            required
            type="text"
            name="contact"
            value={outlet?.contact}
            onChange={(e) => handleChange(e)}
           
            />
            
          </FormControl>
          <br />
        </Box>
      </Flex>
      <br />
      <center>
        <Button
          bgColor={"black"}
          color="#f9a526"
          _hover={{
            color: "black",
            bgColor: "#f9a526",
          }}
          onClick={() => handleUpdate()}
        // onClick={()=>console.log(contact)}
        >
          Save
        </Button>
      </center>
    </Box>
  );
};

export default EditOutlet;
