import { Box, Button, Flex, FormControl, FormLabel, Input, Spinner, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const AddOutlet = () => {
    let url = process.env.REACT_APP_DEV_URL;
    const [city,setCity]=useState([])
    const [outlet, setOutlet] = useState({
      name: "",
      landmark: "",
      location: "",
      city:"",
      contact: "",
      location_link:""
      
    });
    const toast = useToast();
    const navigate = useNavigate();
  
  
    const handleChange = (e) => {
      let { name, value } = e.target;
      setOutlet({ ...outlet, [name]: value });
    };
  
    const getData=async()=>{
      try {
          let data=await fetch(`${url}/city`)
          data=await data.json()
          console.log(data)
          setCity(data.data)
      } catch (error) {
          console.log(error);
      }
  }


  
    const handleAdd = async () => {
  
  
      try {
        let data = await fetch(`${url}/outlet/add`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(outlet),
        });
        data = await data.json();
        // console.log(data);
        toast({
          title: "outlet Added",
          description: data.msg,
          status: "success",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
        navigate("/admin/outlet");
      } catch (error) {
        console.log(error);
        toast({
          title: "Error While Creating Account",
          description: error.message,
          status: "error",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
      }
    };

    useEffect(()=>{
      getData()
    },[])

  return (
    <Box p="4">
      <Flex justifyContent={"center"} gap="40px"  flexDirection={["column","column","column","row","row"]}>
        <Box
          backgroundColor={"white"}
          w={["100%","100%","100%","100%","100%"]}
          boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          padding={"20px"}
          borderRadius={"20px"}
        >
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Name</FormLabel>
            <Input
              required
              variant={"flushed"}
              type="text"
              name="name"
              value={outlet.name}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Landmark</FormLabel>
            <Input
              required
              variant={"flushed"}
              type="text"
              name="landmark"
              value={outlet.landmark}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          {/* <FormControl isRequired>
            <FormLabel color={"#f9a526"}>City</FormLabel>
            <Input
              required
              variant={"flushed"}
              type="text"
              name="city"
              value={outlet.city}
              onChange={(e) => handleChange(e)}
            />
          </FormControl> */}
          <FormControl>
            <FormLabel color={"#f9a526"}>City</FormLabel>
            <select
            style={{
              width: "200px",
              padding: "10px",
              margin:"10px",
              border: "1px solid #f9a526",
              borderRadius: "20px",
            }}
              onChange={(e) =>
                setOutlet({ ...outlet, city: e.target.value })
              }
            >
              <option>Select</option>
              {city &&
                city.map((e) => <option value={e?._id}>{e.name}</option>)}
            </select>
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Location</FormLabel>
            <Input
              required
              variant={"flushed"}
              type="text"
              name="location"
              value={outlet.location}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Location Url</FormLabel>
            <Input
              variant={"flushed"}
              type="text"
              name="location_link"
              value={outlet?.location_link}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Contact</FormLabel>
            <Input
              required
              variant={"flushed"}
              type="text"
              name="contact"
              value={outlet.contact}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
        </Box>
       
      </Flex>
      <br />
      <center>
        <Button
          variant={"solid"}
          bgColor={"black"}
            color="#f9a526"
            _hover={{
            color: "black",
            bgColor: "#f9a526",
            border: "1px solid #f9a526",
            }}
          // onClick={() => submitFile().then((res) => handleAdd(res))}
          onClick={()=>{handleAdd()
          }}
          isDisabled={!outlet.name}
        >
          Add New
        </Button>
      </center>
    </Box>
  )
}

export default AddOutlet