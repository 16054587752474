import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Center,
  Textarea,
  Flex,
  Image,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import generateSlug from "../utility/generateSlug";
import Permalink from "./Permalink";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles for ReactQuill

const EditBlog = () => {
  const { slugname } = useParams();
  const [item, setItem] = useState([]);
  const [singleImg, setSingleImg] = useState("");
  const [selctSinImg, setselectSingImg] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const Navigate = useNavigate();
  const [slug, setSlug] = useState("");
  const url = process.env.REACT_APP_DEV_URL;
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["link", "formula"],
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    ["clean"], // remove formatting button
  ];

  const module = {
    toolbar: toolbarOptions,
  };

  const getData = async () => {
    try {
      const response = await fetch(`${url}/blog/${slugname}`);
      const data = await response.json();

      setItem(data.data);
      setSlug(data.data.slug);
      setDescription(data.data.description);
      console.log("State ById", item);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, [slugname]);

  // edit logic
  const handleSingleImage = (e) => {
    let file = e.target.files[0];
    if (file) {
      setSingleImg(file);

      // Display selected Img
      const imageUrl = URL.createObjectURL(file);
      setselectSingImg(imageUrl);
    } else {
      setSingleImg("");
      setselectSingImg("");
    }
  };
  const handleDeleteSingleImage = () => {
    setSingleImg("");
    setselectSingImg("");
  };
  // image_alt Text logic

  const hancardText = (e) => {
    // let dup = [...item.image_alt];
    let dup = e.target.value;
    setItem({ ...item, image_alt: dup });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setItem({ ...item, [name]: value });
  };
  const handlePlinkChange = (e) => {
    let { name, value } = e.target;
    setItem({ ...item, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!slug) {
      toast({
        title: "Blog Not Add ! ",
        description: "Permalink is Required",
        status: "error",
        position: "top",
        duration: 7000,
        isClosable: true,
      });
      return;
    }
    const formData = new FormData();
    setIsLoading(true);
    let dup = { ...item };
    if (singleImg) {
      formData.append("image", singleImg);
    }
    if (description) {
      dup.description = description;
    }
    let newSlug = generateSlug(slug);
    dup.slug = newSlug;
    formData.append("dup", JSON.stringify(dup));

    try {
      const response = await axios.put(
        `${url}/blog/edit/${slugname}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toast({
          title: "Data Update Successfuly",
          description: response.data.msg,
          status: "success",
          position: "top",
          duration: 7000,
          isClosable: true,
        });
        Navigate("/admin/blog/");
      }
    } catch (error) {
      console.error("Update faild", error);
      toast({
        title: "Data Not Added ",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        position: "top",
        duration: 7000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box p="4">
        <Flex
          justifyContent={"space-around"}
          gap="40px"
          flexDirection={["column", "column", "column", "row", "row"]}
        >
          <Box
            backgroundColor={"#white"}
            w={["100%", "100%", "100%", "100%", "80%"]}
            boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
            padding={"20px"}
            borderRadius={"20px"}
            border={"1px solid #f9a526"}
          >
            <form encType="multipart/form-data">
              <FormControl isRequired mb={1}>
                <FormLabel htmlFor="cardheading" color={"#f9a526"}>
                  Title
                </FormLabel>
                <Input
                  variant="flushed"
                  id="title"
                  type="text"
                  placeholder="Enter your Heading"
                  mb={4}
                  name="title"
                  value={item.title}
                  onChange={(e) => {
                    handlePlinkChange(e);
                  }}
                />
              </FormControl>
              <Permalink slug={slug} setSlug={setSlug} folder={"blog"} />
              <br />
              <FormControl isRequired>
                <FormLabel htmlFor="meta_title" color={"#f9a526"}>
                  Meta Title
                </FormLabel>
                <Input
                  id="meta_title"
                  type="text"
                  placeholder="Enter your Title"
                  variant="flushed"
                  mb={4}
                  name="meta_title"
                  value={item.meta_title}
                  onChange={handleInput}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="meta_description" color={"#f9a526"}>
                  Meta Description
                </FormLabel>
                <Textarea
                  id="meta_description"
                  mb={4}
                  name="meta_description"
                  value={item.meta_description}
                  onChange={handleInput}
                />
              </FormControl>
              <FormControl isRequired mt={5}>
                <FormLabel htmlFor="description" color={"#f9a526"}>
                  Description
                </FormLabel>
                <ReactQuill
                  modules={module}
                  theme="snow"
                  value={description}
                  onChange={setDescription}
                />
              </FormControl>
              <br />
              <FormControl>
                <FormLabel htmlFor="image" color={"#f9a526"}>
                  Image
                </FormLabel>
                <Input
                  variant="flushed"
                  id="image"
                  type="file"
                  name="image"
                  accept="image/*"
                  onChange={handleSingleImage}
                  mb={4}
                />
              </FormControl>
              <FormControl>
                {selctSinImg && (
                  <Flex>
                    <Box>
                      <Image src={selctSinImg} width="200px" />

                      <Input
                        value={item.image_alt}
                        onChange={(event) => hancardText(event)}
                      />
                    </Box>
                    <MdDelete
                      color="red"
                      size={"30px"}
                      cursor="pointer"
                      onClick={handleDeleteSingleImage}
                    />
                  </Flex>
                )}
              </FormControl>
              {!selctSinImg && item.image && (
                <FormControl mr={4}>
                  <Flex>
                    <Box>
                      <img
                        src={`${url}/blog/${item.image}`}
                        alt="selected img"
                        style={{
                          width: "200px",

                          margin: "5px",
                          marginBottom: "10px",
                        }}
                      />

                      <Input
                        value={item.image_alt}
                        onChange={(event) => hancardText(event)}
                      />
                    </Box>
                  </Flex>
                </FormControl>
              )}
              <br />
            </form>
          </Box>
        </Flex>
        <br />
        <center>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <Button
              variant={"solid"}
              bgColor={"black"}
              color="#f9a526"
              _hover={{
                color: "black",
                bgColor: "#f9a526",
                border: "1px solid #f9a526",
              }}
              type="submit"
              isLoading={isLoading}
              spinner={<Spinner color="blue.500" />}
            >
              Save
            </Button>
          </form>
        </center>
      </Box>
    </>
  );
};

export default EditBlog;
