import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";

const ViewTestimonial = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [test, setTest] = useState({});
  const toast = useToast();
  let url = process.env.REACT_APP_DEV_URL;

  const getData = async () => {
    try {
      let data = await fetch(`${url}/testimonial/${id}`);
      data = await data.json();
      console.log(data.data);
      setTest(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (id) => {
    try {
      let data = await fetch(`${url}/testimonial/delete/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      data = await data.json();
      toast({
        title: "Testimonial has been Deleted",
        description: data.msg,
        status: "success",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
      navigate("/admin/testimonial");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Box textAlign={"left"} p="4">
      <Flex gap="20px">
        <Text fontSize={"xl"} fontWeight={"semibold"}>
          View Product Details
        </Text>
        <Button
          borderRadius={"20px"}
          color={"#f9a526"}
          bgColor={"black"}
          _hover={{ color: "black", bgColor: "#f9a526" }}
          leftIcon={<BiEditAlt />}
          onClick={() => navigate(`/admin/testimonial/edit/${id}`)}
        >
          Edit
        </Button>
        <Button
          borderRadius={"20px"}
          color={"#f9a526"}
          bgColor={"black"}
          _hover={{ color: "black", bgColor: "#f9a526" }}
          leftIcon={<RiDeleteBin6Line />}
          onClick={() => handleDelete(id)}
        >
          Delete
        </Button>
      </Flex>
      <br />
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Name
      </Text>
      <Box
        padding="10px 20px"
        width={["100%","80%","50%","50%","50%"]}
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {test?.name}
      </Box>
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Designation
      </Text>
      <Text
        padding="10px 20px"
        width={["100%","80%","50%","50%","50%"]}
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {test?.designation}
      </Text>
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Description
      </Text>
      <Textarea
        padding="10px 20px"
        width={["100%","80%","50%","50%","50%"]}
        bgColor={"#eef1f4"}
        value={test?.description}
        fontSize={"medium"}
      />
      <br />
      <br />
   { test?.image&&(<>
   <Text fontWeight={"semibold"} fontSize={"xl"}>
    Image
    </Text>
    <Image src={`${url}/testimonial/${test?.image}`} borderRadius={"50%"} />
   </>)}
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Created at
      </Text>
      {test?.createdAt && (
        <Box
          padding="10px 20px"
          width={["100%","80%","50%","50%","50%"]}

          bgColor={"#eef1f4"}
          fontSize={"medium"}
        >
          {new Date(test?.createdAt).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          })}
        </Box>
      )}
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Updated at
      </Text>
      {test?.modifiedAt && (
        <Box
          padding="10px 20px"
          width={["100%","80%","50%","50%","50%"]}

          bgColor={"#eef1f4"}
          fontSize={"medium"}
        >
          {new Date(test?.modifiedAt).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          })}
        </Box>
      )}
    </Box>
  );
};

export default ViewTestimonial;
