import { Box, Button, Flex, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiEditAlt } from 'react-icons/bi'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { useNavigate, useParams } from 'react-router-dom'

const ViewCity = () => {
    const {id}=useParams()
    const navigate=useNavigate()
    const [city,setCity]=useState({})
    let url=process.env.REACT_APP_DEV_URL
    const toast=useToast()
    const getData=async()=>{
        try {
            let data=await fetch(`${url}/city/${id}`)
            data=await data.json()
            console.log(data.data);
            setCity(data.data[0])
        } catch (error) {
            console.log(error);
        }
    }
    const handleDelete=async()=>{
      try {
        let res=await fetch(`${url}/city/delete/${id}`,
        {
            method:"DELETE",
            headers:{
                "Content-Type": "application/json"
            }
        })
        res=await res.json()
        //console.log(res);
        toast({
          title: 'City Deleted',
          description: res.msg,
          status: 'success',
          position:"top",
          duration: 7000,
          isClosable: true,
        })
        navigate("/admin/city")
    } catch (error) {
        console.log(error);
        toast({
          title: "Invalid Response",
          description: error.message,
          status: 'error',
          position:"top",
          duration: 7000,
          isClosable: true,
        })
    }
    }
    useEffect(()=>{
        getData()
    },[])
  return (
    <Box textAlign={"left"} p="4">
         <Flex gap="20px">
        <Text fontSize={"xl"} fontWeight={"semibold"}>View City Details</Text>
        <Button
            borderRadius={"20px"}
            color={"#f9a526"}
            bgColor={"black"}
            _hover={{color:"black",bgColor:"#f9a526"}}
          leftIcon={<BiEditAlt/>}
          onClick={() => navigate(`/admin/city/edit/${id}`)}
        >
          Edit
        </Button>
        <Button
        borderRadius={"20px"}
        color={"#f9a526"}
        bgColor={"black"}
        _hover={{color:"black",bgColor:"#f9a526"}}
      leftIcon={<RiDeleteBin6Line/>}
        onClick={handleDelete}
        >
            Delete
        </Button>
      </Flex>
      <br />
      <br />
      <Text fontWeight={"semibold"}  fontSize={"xl"}>Name</Text>
      <Box padding="10px 20px" width="50%" bgColor={"#eef1f4"} fontSize={"medium"}>{city?.name}</Box>
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>Created at</Text>
      {city.createdAt ?
       
       <Box padding="10px 20px" width="50%" bgColor={"#eef1f4"} fontSize={"medium"}>
          {new Date(city.createdAt).toLocaleString("en-IN", {
    timeZone: "Asia/Kolkata",
  })}
        </Box>
      :<Text>No Records</Text>}
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>Updated at</Text>
      {city.modifiedAt ? 
       
        <Box padding="10px 20px" width="50%" bgColor={"#eef1f4"} fontSize={"medium"}>
          {new Date(city.modifiedAt).toLocaleString("en-IN", {
    timeZone: "Asia/Kolkata",
  })}
        </Box>
      :<Text>No Records</Text>}
    </Box>
  )
}

export default ViewCity