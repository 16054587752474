import { Box, Button, Flex, Image, Text, Textarea, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { BiEditAlt } from 'react-icons/bi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';

const ViewOutlet = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [outlet, setOutlet] = useState({});
    const toast = useToast();
    let url = process.env.REACT_APP_DEV_URL;
  
    const getData = async () => {
      try {
        let data = await fetch(`${url}/outlet/${id}`);
        data = await data.json();
        console.log(data.data);
        setOutlet(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    const handleDelete = async (id) => {
      try {
        let data = await fetch(`${url}/outlet/delete/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        });
        data = await data.json();
        toast({
          title: "outlet has been Deleted",
          description: data.msg,
          status: "success",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
        navigate("/admin/outlet");
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      getData();
    }, []);
  return (
    <Box textAlign={"left"} p="4">
      <Flex gap="20px">
        <Text fontSize={"xl"} fontWeight={"semibold"}>
          View Outlet Details
        </Text>
        <Button
          borderRadius={"20px"}
          color={"#f9a526"}
          bgColor={"black"}
          _hover={{ color: "black", bgColor: "#f9a526" }}
          leftIcon={<BiEditAlt />}
          onClick={() => navigate(`/admin/outlet/edit/${id}`)}
        >
          Edit
        </Button>
        <Button
          borderRadius={"20px"}
          color={"#f9a526"}
          bgColor={"black"}
          _hover={{ color: "black", bgColor: "#f9a526" }}
          leftIcon={<RiDeleteBin6Line />}
          onClick={() => handleDelete(id)}
        >
          Delete
        </Button>
      </Flex>
      <br />
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Name
      </Text>
      <Box
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {outlet.name}
      </Box>
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Landmark
      </Text>
      <Text
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {outlet?.landmark}
      </Text>
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        City
      </Text>
      <Text
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {outlet?.city?.name}
      </Text>
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Location
      </Text>
      <Text
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {outlet?.location}
      </Text>
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
      Location Url
    </Text>
    <Text
      padding="10px 20px"
      width="50%"
      bgColor={"#eef1f4"}
      fontSize={"medium"}
    >
      {outlet?.location_url}
    </Text>
    <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Contact
      </Text>
      <Text
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {outlet?.contact}
      </Text>
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Created at
      </Text>
      {outlet.createdAt && (
        <Box
          padding="10px 20px"
          width="50%"
          bgColor={"#eef1f4"}
          fontSize={"medium"}
        >
          {new Date(outlet.createdAt).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          })}
        </Box>
      )}
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Updated at
      </Text>
      {outlet.modifiedAt && (
        <Box
          padding="10px 20px"
          width="50%"
          bgColor={"#eef1f4"}
          fontSize={"medium"}
        >
          {new Date(outlet.modifiedAt).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          })}
        </Box>
      )}
    </Box>
  )
}

export default ViewOutlet