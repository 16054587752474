import { Box, Button, ButtonGroup, Flex, FormControl, FormLabel, Input, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const AddCategory = () => {
    const [category,setCategory]=useState({
        name:"",
    })

    const navigate=useNavigate()
    const toast=useToast()
    let url=process.env.REACT_APP_DEV_URL

    const handleSave=async()=>{
        try {
            let data=await fetch(`${url}/category/add`,{
                method:"POST",
                headers:{
                    "Content-Type": "application/json",
                },
                body:JSON.stringify(category)
            })
            data=await data.json()
            toast({
                title: 'New Category Created',
                description: data.msg,
                status: 'success',
                position:"top",
                duration: 7000,
                isClosable: true,
              })
                navigate("/admin/category")
        } catch (error) {
            toast({
                title: "Invalid Response",
                description: error.message,
                status: 'error',
                position:"top",
                duration: 7000,
                isClosable: true,
              })
        }
    }


    const handleChange=(e)=>{
        const {name,value}=e.target
        setCategory({...category,[name]:value})
    }
  return (
    <Box p="4">
        <Flex justifyContent={"center"}  >
            <Box   border={"1px solid #f9a526"} width={["100%","80%","60%","50%"]} padding={"20px"} borderRadius="20px">
                <FormControl isRequired >
                    <FormLabel color={"#f9a526"} m={"0"} >Name</FormLabel>
                    <Input type="text" color={"black"} borderColor={"#f9a526"} value={category.name} name="name" onChange={(e)=>handleChange(e)} />
                </FormControl>
                <br />
                <ButtonGroup gap="40px">
                <Button
                variant={"solid"}
                bgColor={"#161616"}
                color="#f9a526"
                _hover={{
                color: "black",
                bgColor: "#f9a526",
                border: "1px solid #f9a526",
                }}
                onClick={handleSave}
                isDisabled={!category.name}
                >
                Save
                </Button>
                    </ButtonGroup>
            </Box>
        </Flex>
    </Box>
  )
}

export default AddCategory