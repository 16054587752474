import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  SimpleGrid,
  Spinner,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/checkbox.css";
import switchAudio from "../audio/light-switch.mp3";
import "../styles/loader.css";
import { SketchPicker } from "react-color";
import ColorTaker from "./ColorTaker";
import { MdDelete } from "react-icons/md";

const Home = () => {
  const [dataUrl, setDataUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [product, setProduct] = useState([]);
  const [aboutUrl, setAboutUrl] = useState("");
  const [aboutImage, setAboutImage] = useState({});
  const [traditionImage, setTraditionImage] = useState({});
  const [philosophyImage, setPhilosophyImage] = useState({});
  const [traditionUrl, setTraditionUrl] = useState("");
  const [philosophyUrl, setPhilosophyUrl] = useState("");
  const [bannerImage, setBannerImage] = useState([]);
  const [bannerUrl, setBannerUrl] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState([]);
  const [backgroundUrl, setBackgroundUrl] = useState([]);
  const [menu, setMenu] = useState([]);
  const [color, setColor] = useState([
    "#cc1b1e",
    "#cc1b1e",
    "#cc1b1e",
    "#cc1b1e",
  ]);
  const [titleColor, setTitleColor] = useState([
    "#cc1b1e",
    "#cc1b1e",
    "#cc1b1e",
    "#cc1b1e",
  ]);
  const [textColor, setTextColor] = useState([
    "#cc1b1e",
    "#cc1b1e",
    "#cc1b1e",
    "#cc1b1e",
  ]);
  const [btnColor, setBtnColor] = useState([
    "#cc1b1e",
    "#cc1b1e",
    "#cc1b1e",
    "#cc1b1e",
  ]);
  const navigate = useNavigate();
  const toast = useToast();
  let url = process.env.REACT_APP_DEV_URL;

  let audio = new Audio(switchAudio);

  const [detail, setDetail] = useState({
    top_seller: [],
    about_us: "",
    about_image: "",
    classy_tradition: "",
    classy_tradition_image: "",
    philosophy: "",
    philosophy_image: "",
    our_menu: [],
    bg: color,
    title_color: titleColor,
    text_color: textColor,
    btn_color: btnColor,
    banner_image: "",
    banner_bg: "",
  });

  const getCategory = async () => {
    try {
      let data = await fetch(`${url}/category`);
      data = await data.json();
      setCategory(data.data);
      console.log("category", data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetail({ ...detail, [name]: value });
  };

  const getMenuCheck = useCallback(
    (id) => {
      let exist = menu?.findIndex((e) => e._id === id);
      if (exist > -1) {
        return true;
      } else {
        return false;
      }
    },
    [menu]
  );

  const getCheck = useCallback(
    (id) => {
      let exist = product?.findIndex((e) => e._id === id);
      if (exist > -1) {
        return true;
      } else {
        return false;
      }
    },
    [product]
  );
  const handleMenuCheck = (e) => {
    audio.play();
    let updatedProduct = [...menu];
    let index = updatedProduct?.findIndex((i) => i._id == e._id);
    console.log(index);
    if (index > -1) {
      updatedProduct.splice(index, 1);
    } else {
      if (updatedProduct.length >= 4) {
        updatedProduct.pop();
      } else {
        // setProduct([...product, e._id]);
        updatedProduct.push(e);
      }
    }
    setMenu(updatedProduct);
  };

  const handleCheck = (e) => {
    audio.play();
    let updatedProduct = [...product];
    let index = updatedProduct?.findIndex((i) => i._id == e._id);
    console.log(index);
    console.log(product);
    if (index > -1) {
      updatedProduct.splice(index, 1);
    } else {
      if (updatedProduct.length >= 4) {
        updatedProduct.pop();
      } else {
        // setProduct([...product, e._id]);
        updatedProduct.push(e);
      }
    }
    console.log("updatedProduct", updatedProduct);

    setProduct(updatedProduct);
  };

  const editHome = async (ai, ti, pi, bi, bb) => {
    let updatedDetail = { ...detail };
    // if (resImage) {
    //   updatedDetail = { ...updatedDetail, bottom_image: resImage };
    // }
    if (ai) {
      updatedDetail = { ...updatedDetail, about_image: ai };
    }
    if (ti) {
      updatedDetail = { ...updatedDetail, classy_tradition_image: ti };
    }
    if (pi) {
      updatedDetail = { ...updatedDetail, philosophy_image: pi };
    }
    if (product) {
      updatedDetail = { ...updatedDetail, top_seller: product };
    }
    if (menu) {
      updatedDetail = { ...updatedDetail, our_menu: menu };
    }
    if (color) {
      updatedDetail = { ...updatedDetail, bg: color };
    }
    if (titleColor) {
      updatedDetail = { ...updatedDetail, title_color: titleColor };
    }
    if (textColor) {
      updatedDetail = { ...updatedDetail, text_color: textColor };
    }
    if (btnColor) {
      updatedDetail = { ...updatedDetail, btn_color: btnColor };
    }
    if (bi?.length>0) {
      updatedDetail = { ...updatedDetail, banner_image: [...updatedDetail.banner_image,...bi] };
    }
    if (bb?.length>0) {
      updatedDetail = { ...updatedDetail, banner_bg: [...updatedDetail.banner_bg,...bb] };
    }
    setDetail(updatedDetail);
    try {
      let data = await fetch(`${url}/home/edit/${detail._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedDetail),
      });
      data = await data.json();
      console.log(data);
      toast({
        title: "Home Page Edited",
        description: data.msg,
        status: "success",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
      navigate("/admin/page");
    } catch (error) {
      toast({
        title: "Error In Uploading Updates",
        description: error.message,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  const submitPhilosophyChanger = async () => {
    let formData = new FormData();
    if (!philosophyUrl) {
      return;
    }
    formData.append("home", philosophyImage);
    setIsLoading(true);
    try {
      let data = await axios.post(`${url}/home/image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(data.data.data);
      setIsLoading(false);
      toast({
        title: "Image Uploaded Successfully",
        description: "",
        status: "success",
        position: "top",
        duration: 9000,
        isClosable: true,
      });

      // setDetail({ ...detail, philosophy_image: data.data.data });
      // setAboutImage(data.data.data);
      return data.data.data;
    } catch (error) {
      console.log(error);
      toast({
        title: "Error In Uploading Image",
        description: error.message,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handlePhilosophyChanger = async (e) => {
    let file = e.target.files[0];
    console.log(file);

    setPhilosophyImage(file);
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setPhilosophyUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const submitTraditionImage = async () => {
    let formData = new FormData();
    if (!traditionUrl) {
      return;
    }
    formData.append("home", traditionImage);
    setIsLoading(true);
    try {
      let data = await axios.post(`${url}/home/image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(data.data.data);
      setIsLoading(false);
      toast({
        title: "Image Uploaded Successfully",
        description: "",
        status: "success",
        position: "top",
        duration: 9000,
        isClosable: true,
      });

      setDetail({ ...detail, classy_tradition_image: data.data.data });
      // setAboutImage(data.data.data);
      return data.data.data;
    } catch (error) {
      console.log(error);
      toast({
        title: "Error In Uploading Image",
        description: error.message,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleTraditionChanger = async (e) => {
    let file = e.target.files[0];
    console.log(file);
    let ai = file;
    setTraditionImage(file);
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setTraditionUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAboutChanger = async (e) => {
    let file = e.target.files[0];
    console.log(file);
    let ai = file;
    setAboutImage(file);
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setAboutUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBannerImageChanger = async (e) => {
    let file = e.target.files[0];
    console.log(file);
    let ai = file;
    setBannerImage([...bannerImage, file]);
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setBannerUrl([...bannerUrl, reader.result]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBackgroundImageChanger = async (e) => {
    let file = e.target.files[0];
    console.log(file);
    let ai = file;
    setBackgroundImage([...backgroundImage, file]);
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setBackgroundUrl([...backgroundUrl, reader.result]);
      };
      reader.readAsDataURL(file);
    }
  };
  const submitBackgroundImage = async () => {
    let formData = new FormData();

    if (!backgroundUrl.length) {
      return;
    }
    for (let x of backgroundImage) {
      formData.append("banner", x);
    }
    setIsLoading(true);
    try {
      let data = await axios.post(`${url}/home/banner/image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(data.data.data);
      setIsLoading(false);
      toast({
        title: "Image Uploaded Successfully",
        description: "",
        status: "success",
        position: "top",
        duration: 9000,
        isClosable: true,
      });

      // setDetail({ ...detail, banner_bg: data.data.data });
      // setAboutImage(data.data.data);
      return data.data.data;
    } catch (error) {
      console.log(error);
      toast({
        title: "Error In Uploading Image",
        description: error.message,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const submitBannerImage = async () => {
    let formData = new FormData();

    if (!bannerUrl?.length) {
      return;
    }
    for (let x of bannerImage) {
      formData.append("banner", x);
    }
    setIsLoading(true);
    try {
      let data = await axios.post(`${url}/home/banner/image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(data.data.data);
      setIsLoading(false);
      toast({
        title: "Image Uploaded Successfully",
        description: "",
        status: "success",
        position: "top",
        duration: 9000,
        isClosable: true,
      });

      // setDetail({ ...detail, banner_image: data.data.data });
      // setAboutImage(data.data.data);
      return data.data.data;
    } catch (error) {
      console.log(error);
      toast({
        title: "Error In Uploading Image",
        description: error.message,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const submitAboutImage = async () => {
    let formData = new FormData();

    if (!aboutUrl) {
      return;
    }
    formData.append("home", aboutImage);
    setIsLoading(true);
    try {
      let data = await axios.post(`${url}/home/image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(data.data.data);
      setIsLoading(false);
      toast({
        title: "Image Uploaded Successfully",
        description: "",
        status: "success",
        position: "top",
        duration: 9000,
        isClosable: true,
      });

      setDetail({ ...detail, about_image: data.data.data });
      // setAboutImage(data.data.data);
      return data.data.data;
    } catch (error) {
      console.log(error);
      toast({
        title: "Error In Uploading Image",
        description: error.message,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
      setAboutUrl("");
    }
  };

  const handleBannerLocal = (i) => {
    let dup = [...bannerUrl];

    dup.splice(i, 1);
    setBannerUrl(dup);
  };


  const handleBanner = (i) => {
    let dup = [...detail.banner_image];
    dup.splice(i, 1);
    setDetail({ ...detail, banner_image: dup });
  };

  const handleBackgroundLocal=(i)=>{
    let dup=[...backgroundUrl]
    dup.splice(i,1)
    setBackgroundUrl(dup)
  }

  const handleBackground=(i)=>{
    let dup=[...detail.banner_bg]
    dup.splice(i,1)
    setDetail({...detail,banner_bg:dup})
  }

  const handleColorChange = (index, newColor) => {
    const upDateColor = [...color];
    upDateColor[index] = newColor;
    setColor(upDateColor);
  };
  const handleTitleChange = (index, newColor) => {
    const updateTitle = [...titleColor];
    updateTitle[index] = newColor;
    setTitleColor(updateTitle);
  };

  const handleTextChange = (index, newColor) => {
    const updateText = [...textColor];
    updateText[index] = newColor;
    setTextColor(updateText);
  };

  const handleBtnChange = (index, newColor) => {
    const updateText = [...btnColor];
    updateText[index] = newColor;
    setBtnColor(updateText);
  };

  const getHome = async () => {
    try {
      let data = await fetch(`${url}/home`);
      data = await data.json();
      console.log(data.data[0]);
      setDetail(data.data[0]);
      setProduct(data.data[0].top_seller);
      setMenu(data.data[0].our_menu);
      data.data[0].bg.length > 0 && setColor(data.data[0].bg);
      data.data[0].title_color.length > 0 &&
        setTitleColor(data.data[0].title_color);
      data.data[0].text_color.length > 0 &&
        setTextColor(data.data[0].text_color);
      data.data[0].btn_color.length > 0 && setBtnColor(data.data[0].btn_color);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHome();
    getCategory();
  }, []);
  return (
    <Box p="4">
      <Box
        backgroundColor={"white"}
        boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
        padding={"20px"}
        borderRadius={"20px"}
      >
        <FormControl isRequired>
          <FormLabel color="#f9a526">Banner Image</FormLabel>
          {bannerUrl?.length>0 &&
            bannerUrl?.map((e, i) => {
              return (
                <Flex>
                  <Image src={e} w="80%" />
                  <MdDelete
                    color="red"
                    size={"30px"}
                    onClick={() => handleBannerLocal(i)}
                  />
                </Flex>
              );
            })}
          {detail?.banner_image?.length>0 &&
            detail?.banner_image?.map((e, i) => {
              return (
                <Flex>
                  <Image src={`${url}/home/${e}`} w="80%" />
                  <MdDelete
                    color="red"
                    size={"30px"}
                    onClick={() => handleBanner(i)}
                  />
                </Flex>
              );
            })}
          <br />
          <form encType="multipart/form-data">
            <input
              required
              type="file"
              name="banner"
              onChange={(e) => handleBannerImageChanger(e)}
            />
          </form>
          <Text>
            <span style={{ fontWeight: "bold" }}>Note</span>:File Size Should Be
            665px x 439px (for Better Result) and png file will allow Only
          </Text>
        </FormControl>
        <br />
        <FormControl isRequired>
          <FormLabel color="#f9a526">Banner Background Image</FormLabel>
          {backgroundUrl.length>0 && (
            backgroundUrl.map((e,i)=>{
              return (
                <Flex>
                  <Image src={e} w="80%" />
                  <MdDelete
                    color="red"
                    size={"30px"}
                    onClick={() => handleBackgroundLocal(i)}
                  />
                </Flex>
              )
            })
          ) 
        }
        {
          detail.banner_bg?.length>0&&detail.banner_bg?.map((e,i)=>{
            return (
              <Flex>
            <Image src={`${url}/home/${e}`} w="80%" />
                <MdDelete
                  color="red"
                  size={"30px"}
                  onClick={() => handleBackground(i)}
                />
              </Flex>
            )
          })
        }
          <br />
          <form encType="multipart/form-data">
            <input
              required
              type="file"
              name="home"
              onChange={(e) => handleBackgroundImageChanger(e)}
            />
          </form>
          <Text>
            <span style={{ fontWeight: "bold" }}>Note</span>:File Size Should Be
            1497px x 646px (for Better Result) and png file will allow Only
          </Text>
        </FormControl>
        <br />
        <FormControl>
          <FormLabel color="#f9a526">About Us</FormLabel>
          <Textarea
            value={detail?.about_us}
            name="about_us"
            onChange={(e) => handleChange(e)}
          />
        </FormControl>
        <br />
        <FormControl isRequired>
          <FormLabel color="#f9a526">About Us Image</FormLabel>
          {aboutUrl ? (
            <Image src={aboutUrl} />
          ) : (
            <Image src={`${url}/home/${detail?.about_image}`} />
          )}
          <br />
          <form encType="multipart/form-data">
            <input
              required
              type="file"
              name="home"
              onChange={(e) => handleAboutChanger(e)}
            />
          </form>
          <Text>
            <span style={{ fontWeight: "bold" }}>Note</span>:File Size Should Be
            Less than 500KB and png file will allow Only
          </Text>
        </FormControl>
        <br />
        <FormControl>
          <FormLabel color="#f9a526">Classy Tradition</FormLabel>
          <Textarea
            value={detail?.classy_tradition}
            maxLength={513}
            name="classy_tradition"
            onChange={(e) => handleChange(e)}
          />
        </FormControl>
        <br />
        <FormControl isRequired>
          <FormLabel color="#f9a526">Classy Tradition Image</FormLabel>
          {traditionUrl ? (
            <Image src={traditionUrl} />
          ) : (
            <Image src={`${url}/home/${detail?.classy_tradition_image}`} />
          )}
          <br />
          <form encType="multipart/form-data">
            <input
              required
              type="file"
              name="home"
              onChange={(e) => handleTraditionChanger(e)}
            />
          </form>
          <Text>
            <span style={{ fontWeight: "bold" }}>Note</span>:File Size Should Be
            Less than 500KB and png file will allow Only
          </Text>
        </FormControl>
        <br />
        <FormControl>
          <FormLabel color="#f9a526">Philosophy</FormLabel>
          <Textarea
            value={detail?.philosophy}
            name="philosophy"
            onChange={(e) => handleChange(e)}
          />
        </FormControl>
        <br />
        <FormControl isRequired>
          <FormLabel color="#f9a526">Philosophy Image</FormLabel>
          {philosophyUrl ? (
            <Image src={philosophyUrl} />
          ) : (
            <Image src={`${url}/home/${detail?.philosophy_image}`} />
          )}
          <br />
          <form encType="multipart/form-data">
            <input
              required
              type="file"
              name="home"
              onChange={(e) => handlePhilosophyChanger(e)}
            />
          </form>
          <Text>
            <span style={{ fontWeight: "bold" }}>Note</span>:File Size Should Be
            Less than 500KB and png file will allow Only
          </Text>
        </FormControl>
        <br />
        <FormControl>
          <FormLabel color="#f9a526">Top Sellers</FormLabel>
          <Text fontWeight={"bold"} color={"gray"}>
            Please Select only 4 Items
          </Text>
          <br />
          <Box>
            {category &&
              category?.map((e) => {
                return (
                  <>
                    <Heading
                      fontSize={"larger"}
                      fontWeight={"bold"}
                      color={"#f9a526"}
                      textAlign={"center"}
                    >
                      {e.name}
                    </Heading>
                    <br />
                    <Flex>
                      <SimpleGrid
                        ml={["10%"]}
                        columns={[1, 2, 3, 3, 4]}
                        spacing={"40px"}
                      >
                        {e.products?.map((x, i) => (
                          <Flex gap="20px" alignItems={"center"}>
                            {/* <input
                            style={{}}
                            type="checkbox"
                            onChange={() => handleCheck(x)}
                            checked={getCheck(x._id)}
                          /> */}
                            <div class="checkbox-wrapper-55">
                              <label class="rocker rocker-small">
                                <input
                                  type="checkbox"
                                  onChange={() => handleCheck(x)}
                                  checked={getCheck(x._id)}
                                />
                                <span class="switch-left">Yes</span>
                                <span class="switch-right">No</span>
                              </label>
                            </div>
                            <Text>{x?.name}</Text>
                          </Flex>
                        ))}
                      </SimpleGrid>
                    </Flex>
                  </>
                );
              })}
          </Box>
        </FormControl>
        <br />
        <FormControl>
          <FormLabel color="#f9a526">Our Menu</FormLabel>
          <Flex justifyContent={"space-between"}>
            <Box>
              <Text textAlign={"left"}>Back Ground Color</Text>
              <Box>
                {color.map((e, i) => {
                  return (
                    <Box>
                      <Flex>
                        <Text>Box {i + 1}</Text>
                        <ColorTaker
                          color={color[i]}
                          setColor={(newColor) =>
                            handleColorChange(i, newColor)
                          }
                        />
                        <Text>{color[i]}</Text>
                      </Flex>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Text textAlign={"left"}>Tittle Color</Text>
              <Box>
                {titleColor.map((e, i) => {
                  return (
                    <Box>
                      <Flex>
                        <Text>Title {i + 1}</Text>
                        <ColorTaker
                          color={titleColor[i]}
                          setColor={(newColor) =>
                            handleTitleChange(i, newColor)
                          }
                        />
                        <Text>{titleColor[i]}</Text>
                      </Flex>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Text textAlign={"left"}>Text Color</Text>
              <Box>
                {textColor.map((e, i) => {
                  return (
                    <Box>
                      <Flex>
                        <Text>Text {i + 1}</Text>
                        <ColorTaker
                          color={textColor[i]}
                          setColor={(newColor) => handleTextChange(i, newColor)}
                        />
                        <Text>{textColor[i]}</Text>
                      </Flex>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Text textAlign={"left"}>Button Color</Text>
              <Box>
                {btnColor.map((e, i) => {
                  return (
                    <Box>
                      <Flex>
                        <Text>Button {i + 1}</Text>
                        <ColorTaker
                          color={btnColor[i]}
                          setColor={(newColor) => handleBtnChange(i, newColor)}
                        />
                        <Text>{btnColor[i]}</Text>
                      </Flex>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Flex>
          <Text fontWeight={"bold"} color={"gray"}>
            Please Select only 4 Items
          </Text>
          <br />
          <Box>
            {category &&
              category?.map((e) => {
                return (
                  <>
                    <Heading
                      fontSize={"larger"}
                      fontWeight={"bold"}
                      color={"#f9a526"}
                      textAlign={"center"}
                    >
                      {e.name}
                    </Heading>
                    <br />
                    <Flex>
                      <SimpleGrid
                        ml={["10%"]}
                        columns={[1, 2, 3, 3, 4]}
                        spacing={"40px"}
                      >
                        {e.products?.map((x, i) => (
                          <Flex gap="20px" alignItems={"center"}>
                            {/* <input
                            style={{}}
                            type="checkbox"
                            onChange={() => handleCheck(x)}
                            checked={getCheck(x._id)}
                          /> */}
                            <div class="checkbox-wrapper-55">
                              <label class="rocker rocker-small">
                                <input
                                  type="checkbox"
                                  onChange={() => handleMenuCheck(x)}
                                  checked={getMenuCheck(x._id)}
                                />
                                <span class="switch-left">Yes</span>
                                <span class="switch-right">No</span>
                              </label>
                            </div>
                            <Text>{x?.name}</Text>
                          </Flex>
                        ))}
                      </SimpleGrid>
                    </Flex>
                  </>
                );
              })}
          </Box>
        </FormControl>
        <Box>
          <Button
            variant={"solid"}
            bgColor={"black"}
            color="#f9a526"
            _hover={{
              color: "black",
              bgColor: "#f9a526",
              border: "1px solid #f9a526",
            }}
            leftIcon={isLoading && <Spinner color="blue.500" />}
            onClick={() =>
              Promise.all([
                submitAboutImage(),
                submitTraditionImage(),
                submitPhilosophyChanger(),
                submitBannerImage(),
                submitBackgroundImage(),
              ])
                .then((res) => 
                  setTimeout(()=> editHome(res[0], res[1], res[2], res[3], res[4]),5000)
                )
                .catch((err) => console.log(err))
            }
          >
            Save Home
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
