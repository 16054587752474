import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Spinner,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import generateSlug from "../utility/generateSlug";
import Permalink from "./Permalink";
import { MdDelete } from "react-icons/md";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles for ReactQuill

const AddBlog = () => {
  let url = process.env.REACT_APP_DEV_URL;
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [dataUrl, setDataUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const toast = useToast();
  const [slug, setSlug] = useState("");
  const navigate = useNavigate();

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["link", "formula"],
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];

  const module = {
    toolbar: toolbarOptions,
  };

  const handleFileChange = (e) => {
    let file = e.target.files[0];
    if (file) {
      setImage(file);

      // Display img
      const imageUrl = URL.createObjectURL(file);
      setDataUrl(imageUrl);
    } else {
      e.target.value = "";
    }
  };

  const handleDeleteSingleImage = () => {
    setDataUrl("");
    setImage("");
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    if (!slug) {
      toast({
        title: "Blog Not Add ! ",
        description: "Permalink is Required",
        status: "error",
        position: "top",
        duration: 7000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append(
      "dup",
      JSON.stringify({
        title: data.title,
        slug: slug,
        description: description,
        meta_title: data.meta_title,
        meta_description: data.meta_description,
        image_alt: data.image_alt,
      })
    );
    formData.append("image", image);

    try {
      let response = await axios.post(`${url}/blog/add`, formData);
      let data = response.data;
      if (data) {
        toast({
          title: "Blog Added",
          description: data.msg,
          status: "success",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
        navigate("/admin/blog");
      } else {
        toast({
          title: "Blog Not Added",
          description: data.msg,
          status: "error",
          position: "top",
          duration: 7000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box p="4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          justifyContent={"space-around"}
          gap="40px"
          flexDirection={["column", "column", "column", "row", "row"]}
        >
          <Box
            backgroundColor={"white"}
            w={["100%", "100%", "100%", "100%", "80%"]}
            boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
            padding={"20px"}
            borderRadius={"20px"}
            border={"1px solid #f9a526"}
          >
            <FormControl isRequired>
              <FormLabel color={"#f9a526"}>Title</FormLabel>
              <Input
                required
                variant={"flushed"}
                type="text"
                {...register("title", { required: true })}
                onChange={(e) => setSlug(generateSlug(e.target.value))}
              />
            </FormControl>
            <br />
            <Permalink slug={slug} folder={"blog"} setSlug={setSlug} />
            <br />
            {/* <FormControl isRequired>
              <FormLabel color={"#f9a526"}>Description</FormLabel>
              <Textarea
                variant="flushed"
                {...register("description", { required: true })}
              />
            </FormControl> */}
            <FormControl>
              <FormLabel color={"#f9a526"}>Description</FormLabel>
              <ReactQuill
                modules={module}
                theme="snow"
                value={description}
                onChange={setDescription}
              />
            </FormControl>
            <br />
            <br />
            <FormControl isRequired>
              <FormLabel color={"#f9a526"}>Meta Title</FormLabel>
              <Input
                required
                variant={"flushed"}
                type="text"
                {...register("meta_title", { required: true })}
              />
            </FormControl>
            <br />
            <FormControl isRequired>
              <FormLabel color={"#f9a526"}>Meta Description</FormLabel>
              <Textarea
                variant="flushed"
                {...register("meta_description", {
                  required: true,
                  maxLength: 250,
                })}
              />
            </FormControl>
            <br />
            <FormControl>
              <FormLabel color={"#f9a526"}>Image</FormLabel>
              {dataUrl && (
                <Flex>
                  <Box>
                    <Image src={dataUrl} width="200px" />
                    <Input
                      placeholder="Add IMG ALT Text"
                      {...register("image_alt")}
                    />
                  </Box>
                  <MdDelete
                    color="red"
                    size={"30px"}
                    cursor="pointer"
                    onClick={handleDeleteSingleImage}
                  />
                </Flex>
              )}
              <br />
              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={handleFileChange}
              />
              <Text>
                Note: Please choose an image with less than 1000px width and
                500px width in PNG format.
              </Text>
            </FormControl>
            <br />
          </Box>
        </Flex>
        <br />
        <center>
          <Button
            variant={"solid"}
            bgColor={"black"}
            color="#f9a526"
            _hover={{
              color: "black",
              bgColor: "#f9a526",
              border: "1px solid #f9a526",
            }}
            leftIcon={isLoading && <Spinner color="blue.500" />}
            type="submit"
            isDisabled={isLoading}
          >
            Add New
          </Button>
        </center>
      </form>
    </Box>
  );
};

export default AddBlog;
