import {
  Avatar,
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { MdContactPage } from "react-icons/md";
import { TbCategory } from "react-icons/tb";
import { FaUser } from "react-icons/fa";
import { BsBriefcaseFill, BsShopWindow, BsCalendarEvent } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";
import { LuPartyPopper } from "react-icons/lu";
import { BiSolidUser, BiLogoGmail } from "react-icons/bi";
import { BsDistributeVertical } from "react-icons/bs";
import userContext from "../context/userDetail";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FiPower } from "react-icons/fi";
import { Route, Routes, useNavigate } from "react-router-dom";
import { FaStore } from "react-icons/fa6";
import { GiModernCity } from "react-icons/gi";
import { GrCatalog } from "react-icons/gr";
import { TiThMenu } from "react-icons/ti";
import Dashboard from "./Dashboard";
import Category from "./Category";
import logo from "../images/logo.png";
import AddCategory from "./AddCategory";
import ViewCategory from "./ViewCategory";
import EditCategory from "./EditCategory";
import Product from "./Product";
import ViewProduct from "./ViewProduct";
import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";
import Page from "./Page";
import Home from "./Home";
import Testimonial from "./Testimonial";
import AddTestimonial from "./AddTestimonial";
import ViewTestimonial from "./ViewTestimonial";
import EditTestimonial from "./EditTestimonial";
import About from "./About";
import Event from "./Event";
import AddEvent from "./AddEvent";
import ViewEvent from "./ViewEvent";
import EditEvent from "./EditEvent";
import Outlet from "./Outlet";
import AddOutlet from "./AddOutlet";
import ViewOutlet from "./ViewOutlet";
import EditOutlet from "./EditOutlet";
import Distributer from "./Distributer";
import AddDistributor from "./AddDistributor";
import ViewDistributer from "./ViewDistributer";
import EditDistributer from "./EditDistributer";
import Inquiry from "./Inquiry";
import ViewInquiry from "./ViewInquiry";
import City from "./City";
import AddCity from "./AddCity";
import ViewCity from "./ViewCity";
import EditCity from "./EditCity";
import User from "./User";
import AddUser from "./AddUser";
import ViewUser from "./ViewUser";
import EditUser from "./EditUser";
import Profile from "./Profile";
import StoreMenu from "./StoreMenu";
import Catalogue from "./Catalogue";
import ProductText from "./ProductText";
import Blog from "./Blog";
import AddBlog from "./AddBlog";
import ViewBlog from "./ViewBlog";
import EditBlog from "./EditBlog";
const Admin = () => {
  const sidebar = useDisclosure();
  const { userData } = useContext(userContext);
  const navigate = useNavigate();
  const url = process.env.REACT_APP_DEV_URL;
  const handleLogOut = () => {
    localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
  };
  const NavItem = (props) => {
    const { icon, children, ...rest } = props;
    return (
      <Flex
        align="center"
        px="4"
        pl="4"
        py="3"
        cursor="pointer"
        color="inherit"
        _dark={{
          color: "gray.400",
        }}
        _hover={{
          bg: "gray.100",
          _dark: {
            bg: "gray.900",
          },
          color: "gray.900",
        }}
        role="group"
        fontWeight="semibold"
        transition=".15s ease"
        {...rest}
      >
        {icon && (
          <Icon
            mx="2"
            boxSize="4"
            _groupHover={{
              color: "black",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    );
  };

  const SidebarContent = (props) => (
    <Box
      as="nav"
      pos="fixed"
      top="0"
      left="0"
      zIndex="sticky"
      h="full"
      pb="10"
      overflowX="hidden"
      overflowY="auto"
      bg="black"
      _dark={{
        bg: "gray.800",
      }}
      border
      color="inherit"
      borderRightWidth="1px"
      w="60"
      {...props}
    >
      <Flex justifyContent={"center"} mt={"20px"} mb={"20px"}>
        <Image
          src={logo}
          width={"120px"}
          _hover={{ cursor: "pointer" }}
          onClick={() => navigate("/admin")}
        />
      </Flex>

      <Flex px="4" py="0" align="center">
        <Text
          fontSize="2xl"
          ml="2"
          mb={2}
          mt={2}
          color="white"
          _dark={{
            color: "white",
          }}
          fontWeight="semibold"
        >
          {userData.name || "Unknown"}
        </Text>
      </Flex>
      <Box w="100%" border={"1px solid #f9a526"}></Box>
      <Flex
        direction="column"
        as="nav"
        fontSize="sm"
        color="white"
        aria-label="Main Navigation"
      >
        <NavItem
          _hover={{ bgColor: "#f9a526", color: "black" }}
          icon={BsBriefcaseFill}
          onClick={() => {
            sidebar.onClose();
            navigate("/admin");
          }}
        >
          Dashboard
        </NavItem>
        <NavItem
          _hover={{ bgColor: "#f9a526", color: "black" }}
          icon={TbCategory}
          onClick={() => {
            sidebar.onClose();
            navigate("/admin/category");
          }}
        >
          Category
        </NavItem>
        <NavItem
          _hover={{ bgColor: "#f9a526", color: "black" }}
          icon={FaUser}
          onClick={() => {
            sidebar.onClose();
            navigate("/admin/user");
          }}
        >
          Users
        </NavItem>
        <NavItem
          _hover={{ bgColor: "#f9a526", color: "black" }}
          icon={BsShopWindow}
          onClick={() => {
            sidebar.onClose();
            navigate("/admin/product");
          }}
        >
          Products
        </NavItem>
        <NavItem
          _hover={{ bgColor: "#f9a526", color: "black" }}
          icon={BsCalendarEvent}
          onClick={() => {
            sidebar.onClose();
            navigate("/admin/event");
          }}
        >
          Events
        </NavItem>
        <NavItem
          _hover={{ bgColor: "#f9a526", color: "black" }}
          icon={GiModernCity}
          onClick={() => {
            sidebar.onClose();
            navigate("/admin/blog");
          }}
        >
          Blog
        </NavItem>
        {/* <NavItem icon={HiCode} onClick={integrations.onToggle}>
            Integrations
            <Icon
              as={MdKeyboardArrowRight}
              ml="auto"
              transform={integrations.isOpen && "rotate(90deg)"}
            />
          </NavItem>
          <Collapse in={integrations.isOpen}>
            <NavItem pl="12" py="2">
              Shopify
            </NavItem>
            <NavItem pl="12" py="2">
              Slack
            </NavItem>
            <NavItem pl="12" py="2">
              Zapier
            </NavItem>
          </Collapse> */}
        <NavItem
          _hover={{ bgColor: "#f9a526", color: "black" }}
          icon={MdContactPage}
          onClick={() => {
            sidebar.onClose();
            navigate("/admin/page");
          }}
        >
          Pages
        </NavItem>
        {/* <NavItem icon={BiLogoBlogger} onClick={()=>navigate("/admin/blog")}>Blogs</NavItem> */}
        <NavItem
          _hover={{ bgColor: "#f9a526", color: "black" }}
          icon={BiLogoGmail}
          onClick={() => {
            sidebar.onClose();
            navigate("/admin/inquiry");
          }}
        >
          Inquiry
        </NavItem>
        <NavItem
          _hover={{ bgColor: "#f9a526", color: "black" }}
          icon={GiModernCity}
          onClick={() => {
            sidebar.onClose();
            navigate("/admin/city");
          }}
        >
          City
        </NavItem>
        <NavItem
          _hover={{ bgColor: "#f9a526", color: "black" }}
          icon={FaStore}
          onClick={() => {
            sidebar.onClose();
            navigate("/admin/outlet");
          }}
        >
          Outlets
        </NavItem>
        <NavItem
          _hover={{ bgColor: "#f9a526", color: "black" }}
          icon={BsDistributeVertical}
          onClick={() => {
            sidebar.onClose();
            navigate("/admin/distributor");
          }}
        >
          Distributors
        </NavItem>
        <NavItem
          _hover={{ bgColor: "#f9a526", color: "black" }}
          icon={TiThMenu}
          onClick={() => {
            sidebar.onClose();
            navigate("/admin/menu");
          }}
        >
          Menu
        </NavItem>
        <NavItem
          _hover={{ bgColor: "#f9a526", color: "black" }}
          icon={GrCatalog}
          onClick={() => {
            sidebar.onClose();
            navigate("/admin/catalogue");
          }}
        >
          catalogue
        </NavItem>
      </Flex>
    </Box>
  );
  useEffect(() => {
    console.log(userData);
  }, [userData]);

  return (
    <Box
      as="section"
      bg="gray.50"
      _dark={{
        bg: "gray.700",
      }}
      minH="100vh"
    >
      <SidebarContent
        display={{
          base: "none",
          md: "unset",
        }}
      />
      <Drawer
        isOpen={sidebar.isOpen}
        onClose={sidebar.onClose}
        placement="left"
      >
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent w="full" borderRight="none" />
        </DrawerContent>
      </Drawer>
      <Box
        ml={{
          base: 0,
          md: 60,
        }}
        transition=".3s ease"
      >
        <Flex
          as="header"
          align="center"
          justify="flex-end"
          px="4"
          bg="black"
          _dark={{
            bg: "gray.800",
          }}
          borderBottomWidth="1px"
          color="inherit"
          h="72px"
        >
          <IconButton
            aria-label="Menu"
            display={{
              base: "inline-flex",
              md: "none",
            }}
            onClick={sidebar.onOpen}
            icon={<FiMenu />}
            size="sm"
          />
          <Flex align="center">
            <Menu>
              <MenuButton rightIcon={<ChevronDownIcon />}>
                {userData.image ===
                "https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-High-Quality-Image.png" ? (
                  <Avatar
                    ml="4"
                    size="sm"
                    name="anubra266"
                    src={userData.image}
                    cursor="pointer"
                  />
                ) : (
                  <Avatar
                    ml="4"
                    size="sm"
                    name="anubra266"
                    src={`${url}/user/` + userData.image}
                    cursor="pointer"
                  />
                )}
              </MenuButton>
              <MenuList>
                <MenuItem gap={"20px"}>
                  {userData.image ===
                  "https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-High-Quality-Image.png" ? (
                    <Avatar
                      ml="4"
                      size="sm"
                      name="anubra266"
                      src={userData.image}
                      cursor="pointer"
                    />
                  ) : (
                    <Avatar
                      ml="4"
                      size="sm"
                      name="anubra266"
                      src={`${url}/user/` + userData.image}
                      cursor="pointer"
                    />
                  )}
                  <Text>{userData.email}</Text>
                </MenuItem>
                <MenuItem gap="20px" onClick={() => navigate("/admin/profile")}>
                  <BiSolidUser />
                  Profile
                </MenuItem>
                <MenuItem gap="20px" onClick={handleLogOut}>
                  <FiPower />
                  LogOut
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
        <Box as="main">
          {/* Add content here, remove div below  */}
          {/* <AllRoutes/> */}
          <Routes>
            {/* <Route path='/admin/login' element={<SignUp/>} /> */}
            {/* <Route path='/admin' element={<Admin/>} /> */}
            <Route path="/" element={<Dashboard />} />
            <Route path="/category" element={<Category />} />
            <Route path="/category/add" element={<AddCategory />} />
            <Route path="/category/:categoryid" element={<ViewCategory />} />
            <Route
              path="/category/edit/:categoryid"
              element={<EditCategory />}
            />
            <Route path="/product" element={<Product />} />
            <Route path="/product/:slugname" element={<ViewProduct />} />
            <Route path="/product/add" element={<AddProduct />} />
            <Route path="/product/edit/:slugname" element={<EditProduct />} />
            <Route path="/page" element={<Page />} />
            <Route path="/home" element={<Home />} />
            <Route path="/testimonial" element={<Testimonial />} />
            <Route path="/testimonial/add" element={<AddTestimonial />} />
            <Route path="/testimonial/:id" element={<ViewTestimonial />} />
            <Route path="/testimonial/edit/:id" element={<EditTestimonial />} />
            <Route path="/about" element={<About />} />
            <Route path="/event" element={<Event />} />
            <Route path="/event/add" element={<AddEvent />} />
            <Route path="/event/:slugname" element={<ViewEvent />} />
            <Route path="/event/edit/:slugname" element={<EditEvent />} />
            <Route path="/outlet" element={<Outlet />} />
            <Route path="/outlet/add" element={<AddOutlet />} />
            <Route path="/outlet/:id" element={<ViewOutlet />} />
            <Route path="/outlet/edit/:id" element={<EditOutlet />} />
            <Route path="/distributor" element={<Distributer />} />
            <Route path="/distributor/add" element={<AddDistributor />} />
            <Route path="/distributor/:id" element={<ViewDistributer />} />
            <Route path="/distributor/edit/:id" element={<EditDistributer />} />
            <Route path="/inquiry" element={<Inquiry />} />
            <Route path="/inquiry/:id" element={<ViewInquiry />} />
            <Route path="/city" element={<City />} />
            <Route path="/city/add" element={<AddCity />} />
            <Route path="/city/:id" element={<ViewCity />} />
            <Route path="/city/edit/:id" element={<EditCity />} />
            <Route path="/user" element={<User />} />
            <Route path="/user/add" element={<AddUser />} />
            <Route path="/user/view/:id" element={<ViewUser />} />
            <Route path="/user/edit/:id" element={<EditUser />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/menu" element={<StoreMenu />} />
            <Route path="/catalogue" element={<Catalogue />} />
            <Route path="/product/text" element={<ProductText />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/add" element={<AddBlog />} />
            <Route path="/blog/:slugname" element={<ViewBlog />} />
            <Route path="/blog/edit/:slugname" element={<EditBlog />} />
          </Routes>
          <Box rounded="md" h="" />
        </Box>
      </Box>
    </Box>
  );
};

export default Admin;
