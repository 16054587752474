import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Input,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { AddIcon, ViewIcon } from "@chakra-ui/icons";
import { BiEditAlt } from "react-icons/bi";
import DeleteBtn from "./DeleteBtn";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

const Blog = () => {
  const [event, setEvent] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [flag, setFlag] = useState(true);
  const url = process.env.REACT_APP_DEV_URL;

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to first page on new search
  };

  const getData = async () => {
    try {
      let res = await fetch(
        `${url}/blog?page=${page}&limit=12&search=${search}`
      );
      let data = await res.json();
      console.log(data);
      setCount(data.count);
      setEvent(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (slug) => {
    try {
      await fetch(`${url}/blog/delete/${slug}`, {
        method: "DELETE",
      });

      getData();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, [page, search]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  return (
    <Box p="4">
      <Flex gap={5} justifyContent={"space-between"}>
        <Button
          leftIcon={<AddIcon />}
          variant={"ghost"}
          bgColor={"black"}
          _hover={{ color: "black", bgColor: "#f9a526" }}
          color="#f9a526"
          border={"1px solid #cfcccc"}
          onClick={() => navigate("/admin/blog/add")}
        >
          Add New
        </Button>
        <Box>
          <span>Search:</span>
          <Input
            color={"black"}
            w="150px"
            onChange={handleSearchChange}
            value={search}
          />
        </Box>
        {/* <Button border={"1px solid #cfcccc"} rightIcon={<DeleteIcon/>}>
                Bulk Delete
            </Button> */}
      </Flex>
      <br />
      <TableContainer border={"1px solid #161616"} borderRadius={"20px"}>
        <Table variant="simple">
          <TableCaption
            borderTop={"1px solid #161616"}
            bgColor={"#f9a526"}
            color={"black"}
          >
            There Are {count} Events
          </TableCaption>
          <Thead bgColor={"black"}>
            <Tr>
              <Th color={"#f9a526"}>#</Th>
              <Th color={"#f9a526"}>Title</Th>
              <Th color={"#f9a526"}>Date</Th>
              <Th color={"#f9a526"}>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {event?.map((e, i) => {
              const serialNumber = (page - 1) * 12 + i + 1;
              const cdate = new Date(e.modifiedAt).toLocaleDateString("en-IN", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              });
              return (
                <Tr key={e._id}>
                  <Td> {serialNumber} </Td>
                  <Td>{e.title}</Td>
                  <Td>{cdate}</Td>

                  <Td>
                    <ButtonGroup>
                      <Button
                        leftIcon={<ViewIcon />}
                        bgColor={"black"}
                        _hover={{ bgColor: "#f9a526", color: "black" }}
                        variant="solid"
                        color="#f9a526"
                        onClick={() => navigate(`/admin/blog/${e.slug}`)}
                      >
                        View
                      </Button>
                      <Button
                        leftIcon={<BiEditAlt />}
                        border="1px solid #f9a526"
                        variant={"outline"}
                        _hover={{ bgColor: "#f9a526", color: "black" }}
                        onClick={() => navigate(`/admin/blog/edit/${e.slug}`)}
                      >
                        Edit
                      </Button>
                      <DeleteBtn handleDelete={() => handleDelete(e?.slug)} />
                    </ButtonGroup>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <br />
      <Flex justifyContent={"center"}>
        {search === "" && (
          <div>
            <Button
              border="1px solid #f9a526"
              bgColor={"black"}
              isDisabled={page === 1}
              onClick={() => handlePageChange(page - 1)}
            >
              <BsArrowLeft color="#f9a526" />
            </Button>
            <Button>{page}</Button>
            <Button
              variant={"outline"}
              border="1px solid #f9a526"
              bgColor={"black"}
              isDisabled={page >= count / 12}
              onClick={() => handlePageChange(page + 1)}
            >
              <BsArrowRight color="#f9a526" />
            </Button>
          </div>
        )}
      </Flex>
    </Box>
  );
};

export default Blog;
