import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Box, Flex } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/toast';
import React, { useState } from 'react'
import { useNavigate } from 'react-router';

const AddDistributor = () => {
    let url = process.env.REACT_APP_DEV_URL;
    const [distributer, setDistributer] = useState({
        city: "",
        mail: "",
        address: "",
        text1:"",
        text2: "",
        contact:"",
        location_link:""

    });
    const [suggestions, setSuggestions] = useState([]);
    const toast = useToast();
    const navigate = useNavigate();

    const handleChange = (e) => {
    let { name, value } = e.target;
    setDistributer({ ...distributer, [name]: value });
    // fetchSuggestion(value)
    };



    const handleAdd = async () => {
      try {
        let data = await fetch(`${url}/distributer/add`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(distributer),
        });
        data = await data.json();
        // console.log(data);
        toast({
          title: "Distributor Added",
          description: data.msg,
          status: "success",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
        navigate("/admin/distributor");
      } catch (error) {
        console.log(error);
        toast({
          title: "Error While Adding Account",
          description: error.message,
          status: "error",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
      }
    };

  return (
    <Box p="4">
    <Flex justifyContent={"center"} gap="40px"  flexDirection={["column","column","column","row","row"]}>
      <Box
        backgroundColor={"white"}
        w={["100%","100%","100%","100%","100%"]}
        boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
        padding={"20px"}
        borderRadius={"20px"}
      >
        <FormControl isRequired>
          <FormLabel color={"#f9a526"}>City</FormLabel>
          <Input
            required
            variant={"flushed"}
            type="text"
            name="city"
            value={distributer.city}
             onChange={(e) => handleChange(e)}
          />
        
        </FormControl>
        <br />
        <FormControl isRequired>
          <FormLabel color={"#f9a526"}>Mail</FormLabel>
          <Input
            required
            variant={"flushed"}
            type="text"
            name="mail"
            value={distributer.mail}
            onChange={(e) => handleChange(e)}
          />
        </FormControl>
        <br />
        <FormControl isRequired>
          <FormLabel color={"#f9a526"}>address</FormLabel>
          <Input
            required
            variant={"flushed"}
            type="text"
            name="address"
            value={distributer.address}
            onChange={(e) => handleChange(e)}
          />
        </FormControl>
        <br />
        <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Location Url</FormLabel>
            <Input
              variant={"flushed"}
              type="text"
              name="location_link"
              value={distributer?.location_link}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
        <FormControl isRequired>
          <FormLabel color={"#f9a526"}>Contact</FormLabel>
          <Input
            required
            variant={"flushed"}
            type="text"
            name="contact"
            value={distributer.contact}
            onChange={(e) => handleChange(e)}
          />
        </FormControl>
        <br />
        <FormControl isRequired>
          <FormLabel color={"#f9a526"}>Text</FormLabel>
          <Input
            required
            variant={"flushed"}
            type="text"
            name="text1"
            value={distributer.text1}
            onChange={(e) => handleChange(e)}
          />
        </FormControl>
        <br />
        <FormControl isRequired>
          <FormLabel color={"#f9a526"}>Text</FormLabel>
          <Input
            required
            variant={"flushed"}
            type="text"
            name="text2"
            value={distributer.text2}
            onChange={(e) => handleChange(e)}
          />
        </FormControl>
        <br />
      </Box>
     
    </Flex>
    <br />
    <center>
      <Button
        variant={"solid"}
        bgColor={"black"}
        color="#f9a526"
        _hover={{
        color: "black",
        bgColor: "#f9a526",
        border: "1px solid #f9a526",
        }}
        // onClick={() => submitFile().then((res) => handleAdd(res))}
        onClick={()=>{handleAdd()
        }}
        isDisabled={!distributer.city}
      >
        Add New
      </Button>
    </center>
  </Box>
  )
}

export default AddDistributor