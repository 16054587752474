import React from "react";

import {  FormControl, FormLabel, Input } from "@chakra-ui/react";
import getSlug from "speakingurl";

const Permalink = ({ slug, setSlug, folder }) => {
  const url = process.env.REACT_APP_DEV_URL;

  const handleSlugChange = (e) => {
    setSlug(e.target.value);
  };

  return (
    <form>
      <FormControl>
        <FormLabel>
          Permalink
          <Input
            isDisabled={true}
            value={url + "/" + folder + "/" + getSlug(slug)}
          />
          <Input type="text" value={slug} onChange={handleSlugChange} />
        </FormLabel>
      </FormControl>
    </form>
  );
};

export default Permalink;
