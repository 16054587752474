import { Button } from '@chakra-ui/button';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/toast';
import React, { useEffect, useState } from 'react'
import { BiEditAlt } from 'react-icons/bi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router';

const ViewDistributer = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [distributer, setDistributer] = useState({});
    const toast = useToast();
    let url = process.env.REACT_APP_DEV_URL;
  
    const getData = async () => {
      try {
        let data = await fetch(`${url}/distributer/${id}`);
        data = await data.json();
        console.log(data.data);
        setDistributer(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    const handleDelete = async (id) => {
      try {
        let data = await fetch(`${url}/distributer/delete/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        });
        data = await data.json();
        toast({
          title: "distributer has been Deleted",
          description: data.msg,
          status: "success",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
        navigate("/admin/distributer");
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      getData();
    }, []);
  return (
    <Box p="4" textAlign={"left"}>
    <Flex gap="20px">
      <Text fontSize={"xl"} fontWeight={"semibold"}>
        View Distributor Details
      </Text>
      <Button
        borderRadius={"20px"}
        color={"#f9a526"}
        bgColor={"black"}
        _hover={{ color: "black", bgColor: "#f9a526" }}
        leftIcon={<BiEditAlt />}
        onClick={() => navigate(`/admin/distributor/edit/${id}`)}
      >
        Edit
      </Button>
      <Button
        borderRadius={"20px"}
        color={"#f9a526"}
        bgColor={"black"}
        _hover={{ color: "black", bgColor: "#f9a526" }}
        leftIcon={<RiDeleteBin6Line />}
        onClick={() => handleDelete(id)}
      >
        Delete
      </Button>
    </Flex>
    <br />
    <br />
    <Text fontWeight={"semibold"} fontSize={"xl"}>
      City
    </Text>
    <Box
      padding="10px 20px"
      width="50%"
      bgColor={"#eef1f4"}
      fontSize={"medium"}
    >
      {distributer.city}
    </Box>
    <br />
    <Text fontWeight={"semibold"} fontSize={"xl"}>
      Mail
    </Text>
    <Text
      padding="10px 20px"
      width="50%"
      bgColor={"#eef1f4"}
      fontSize={"medium"}
    >
      {distributer?.mail}
    </Text>
    <br />
    <Text fontWeight={"semibold"} fontSize={"xl"}>
      Address
    </Text>
    <Text
      padding="10px 20px"
      width="50%"
      bgColor={"#eef1f4"}
      fontSize={"medium"}
    >
      {distributer?.address}
    </Text>
    <br />
    <Text fontWeight={"semibold"} fontSize={"xl"}>
      Location Url
    </Text>
    <Text
      padding="10px 20px"
      width="50%"
      bgColor={"#eef1f4"}
      fontSize={"medium"}
    >
      {distributer?.location_url}
    </Text>
    <br />
    <Text fontWeight={"semibold"} fontSize={"xl"}>
      Contact
    </Text>
    <Text
      padding="10px 20px"
      width="50%"
      bgColor={"#eef1f4"}
      fontSize={"medium"}
    >
      {distributer?.contact}
    </Text>
    <br />
    <Text fontWeight={"semibold"} fontSize={"xl"}>
      Text
    </Text>
    <Text
      padding="10px 20px"
      width="50%"
      bgColor={"#eef1f4"}
      fontSize={"medium"}
    >
      {distributer?.text1}
    </Text>
    <br />
    <Text fontWeight={"semibold"} fontSize={"xl"}>
      Text
    </Text>
    <Text
      padding="10px 20px"
      width="50%"
      bgColor={"#eef1f4"}
      fontSize={"medium"}
    >
      {distributer?.text2}
    </Text>
    <br />
    <Text fontWeight={"semibold"} fontSize={"xl"}>
      Created at
    </Text>
    {distributer.createdAt && (
      <Box
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {new Date(distributer.createdAt).toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata",
        })}
      </Box>
    )}
    <br />
    <Text fontWeight={"semibold"} fontSize={"xl"}>
      Updated at
    </Text>
    {distributer.modifiedAt && (
      <Box
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {new Date(distributer.modifiedAt).toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata",
        })}
      </Box>
    )}
  </Box>
  )
}

export default ViewDistributer