import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Box, Flex } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/toast';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';

const EditDistributer = () => {
    const { id } = useParams();
  let url = process.env.REACT_APP_DEV_URL;
  const [distributer, setDistributer] = useState({});
  const navigate = useNavigate();
  const toast = useToast();

  const handleChange = (e) => {
    let { name, value } = e.target;
    setDistributer({ ...distributer, [name]: value });
  };

  const getData = async () => {
    try {
      let data = await fetch(`${url}/distributer/${id}`);
      data = await data.json();
      console.log(data.data);
      setDistributer(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdate = async () => {

    try {
      let data = await fetch(`${url}/distributer/edit/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(distributer),
      });
      data = await data.json();
      console.log(data);
      toast({
        title: "Distributer has been successfully updated",
        description: data.msg,
        status: "success",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
      navigate("/admin/distributor");
    } catch (error) {
      console.log(error);
      toast({
        title: error.message,
        description: error,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Box p="4">
      <Flex
        justifyContent={"space-around"}
        gap="40px"
        flexDirection={["column", "column", "column", "row", "row"]}
      >
        <Box
          backgroundColor={"white"}
          w={["100%", "100%", "100%", "100%", "100%"]}
          boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          padding={"20px"}
          borderRadius={"20px"}
          border={"1px solid #f9a526"}
        >
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>City</FormLabel>
            <Input
              variant={"flushed"}
              type="text"
              name="city"
              value={distributer?.city}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Mail</FormLabel>
            <Input
              variant={"flushed"}
              type="text"
              name="mail"
              value={distributer?.mail}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Address</FormLabel>
            <Input
              variant={"flushed"}
              type="text"
              name="address"
              value={distributer?.address}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Location Url</FormLabel>
            <Input
              variant={"flushed"}
              type="text"
              name="location_link"
              value={distributer?.location_link}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Contact</FormLabel>
            <Input
              variant={"flushed"}
              type="text"
              name="contact"
              value={distributer?.contact}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Text</FormLabel>
            <Input
              variant={"flushed"}
              type="text"
              name="text1"
              value={distributer?.text1}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Text</FormLabel>
            <Input
            variant={"flushed"}
            required
            type="text"
            name="text2"
            value={distributer?.text2}
            onChange={(e) => handleChange(e)}
           
            />
            
          </FormControl>
          <br />
        </Box>
      </Flex>
      <br />
      <center>
        <Button
          bgColor={"black"}
          color="#f9a526"
          _hover={{
            color: "black",
            bgColor: "#f9a526",
          }}
          onClick={() => handleUpdate()}
        // onClick={()=>console.log(contact)}
        >
          Save
        </Button>
      </center>
    </Box>
  )
}

export default EditDistributer