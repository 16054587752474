import { AddIcon, ViewIcon } from '@chakra-ui/icons'
import { Box, Button, ButtonGroup, Flex, Input, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiEditAlt } from 'react-icons/bi'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

const Inquiry = () => {
  const [inquiry,setInquiry]=useState([])
  const [search,setSearch]=useState("")
  const navigate=useNavigate()
  const [page,setPage]=useState(1)
  const [count,setCount]=useState(0)
  const [flag,setFlag]=useState(true)
  const url=process.env.REACT_APP_DEV_URL

  const getCount=async()=>{
      try {
        let data =await fetch(`${url}/inquiry`)
        data=await data.json()
        console.log(data);
        setCount(data.data.length)
    } catch (error) {
        console.log(error);
    }
    }

  const handleSearch=async()=>{
      if(!search){
          getData()
      }else{
          try {
              let data=await fetch(`${url}/inquiry/search/${search}`)
              data=await data.json()
              setInquiry(data.data)
          } catch (error) {
              console.log(error);
          }
      }
  }

  const getData=async()=>{
      try {
          let data=await fetch(`${url}/inquiry?page=${page}`)
          data=await data.json()
          console.log(data)
          setInquiry(data.data)
      } catch (error) {
          console.log(error);
      }
  }
  const handleDelete=async(id)=>{
      try {
          let res=await fetch(`${url}/outlet/delete/${id}`,
          {
              method:"DELETE",
              headers:{
                  "Content-Type": "application/json"
              }
          })
          res=await res.json()
          console.log(res);
          getData()
      } catch (error) {
          console.log(error);
      }
    }
  useEffect(()=>{
      getData()
      getCount()
  },[page])
  return (
    <Box p="4">
        <div>
    <Flex gap={5} justifyContent={"flex-end"}>
        <Box>
        <span>Search:</span><Input color={"black"}  onBlur={()=>setFlag(true)} w="150px" onChange={(e)=>setSearch(e.target.value)}  value={search} onKeyUp={handleSearch} />
        </Box>
        {/* <Button border={"1px solid #cfcccc"} rightIcon={<DeleteIcon/>}>
            Bulk Delete
        </Button> */}
    </Flex>
    <br/>
    <TableContainer border={"1px solid #161616"} borderRadius={"20px"}>
<Table variant='simple'  >
<TableCaption borderTop={"1px solid #161616"} bgColor={"#f9a526"} color={"black"} >There Are {count} Inquiries</TableCaption>
<Thead bgColor={"black"}>
  <Tr  >
    <Th  color={"#f9a526"}>#</Th>
    <Th color={"#f9a526"}>Inquiry Number</Th>

    <Th color={"#f9a526"}>Name</Th>
    <Th color={"#f9a526"}>Contact</Th>
    <Th color={"#f9a526"}>Email</Th>
    <Th color={"#f9a526"}>City</Th>

    <Th color={"#f9a526"} >Action</Th>
  </Tr>
</Thead>
<Tbody>
{inquiry?.map((e,i)=>{
    return <Tr key={e?._id} >
        <Td> {i+1} </Td>
        <Td> {e.inquiry_number} </Td>

        <Td>{e?.name}</Td>
        <Td> {e?.phone}</Td>
        <Td> {e?.email} </Td>
        <Td> {e?.city} </Td>


        <Td>  
            <ButtonGroup>
            <Button leftIcon={<ViewIcon/>} bgColor={"black"} _hover={{bgColor:"#f9a526",color:"black"}} variant="solid" color="#f9a526" onClick={()=>navigate(`/admin/inquiry/${e._id}`)}>View</Button>
            </ButtonGroup>
        </Td>
    </Tr>
})}
</Tbody>
</Table>
</TableContainer>
<br />
<Flex justifyContent={"center"}>
{flag&&<div>
      <Button  border="1px solid #f9a526" bgColor={"black"} isDisabled={page===1} onClick={()=>setPage(page-1)}><BsArrowLeft color='#f9a526'/></Button>
      <Button>{page}</Button>
      <Button variant={"outline"} border="1px solid #f9a526" bgColor={"black"} isDisabled={page>=(count/12)} onClick={()=>setPage(page+1)}><BsArrowRight color='#f9a526'/></Button>
    </div>
    }
</Flex>
</div>
    </Box>
  )
}

export default Inquiry
