import { AddIcon, ViewIcon } from '@chakra-ui/icons'
import { Box, Button, ButtonGroup, Flex, Input, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiEditAlt } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import DeleteBtn from './DeleteBtn'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'

const Outlet = () => {
    const [outlet,setOutlet]=useState([])
    const [search,setSearch]=useState("")
    const navigate=useNavigate()
    const [page,setPage]=useState(1)
    const [count,setCount]=useState(0)
    const [flag,setFlag]=useState(true)
    const url=process.env.REACT_APP_DEV_URL

    const getCount=async()=>{
        try {
          let data =await fetch(`${url}/outlet`)
          data=await data.json()
          console.log(data);
          setCount(data.data.length)
      } catch (error) {
          console.log(error);
      }
      }

    const handleSearch=async()=>{
        if(!search){
            getData()
        }else{
            try {
                let data=await fetch(`${url}/outlet/search/${search}`)
                data=await data.json()
                setOutlet(data.data)
            } catch (error) {
                console.log(error);
            }
        }
    }

    const getData=async()=>{
        try {
            let data=await fetch(`${url}/outlet?page=${page}`)
            data=await data.json()
            console.log(data)
            setOutlet(data.data)
        } catch (error) {
            console.log(error);
        }
    }
    const handleDelete=async(id)=>{
        try {
            let res=await fetch(`${url}/outlet/delete/${id}`,
            {
                method:"DELETE",
                headers:{
                    "Content-Type": "application/json"
                }
            })
            res=await res.json()
            console.log(res);
            getData()
        } catch (error) {
            console.log(error);
        }
      }
    useEffect(()=>{
        getData()
        getCount()
    },[page])
  return (
    <Box p="4">
    <Flex gap={5} justifyContent={"space-between"}>
        <Button leftIcon={<AddIcon/>} variant={"ghost"} bgColor={"black"}  _hover={{color:"black",bgColor:"#f9a526"}} color="#f9a526" border={"1px solid #cfcccc"} onClick={()=>navigate("/admin/outlet/add")}>
            Add New
        </Button>
        <Box>
        <span>Search:</span><Input color={"black"}  onBlur={()=>setFlag(true)} w="150px" onChange={(e)=>setSearch(e.target.value)}  value={search} onKeyUp={handleSearch} />
        </Box>
        {/* <Button border={"1px solid #cfcccc"} rightIcon={<DeleteIcon/>}>
            Bulk Delete
        </Button> */}
    </Flex>
    <br/>
    <TableContainer border={"1px solid #161616"} borderRadius={"20px"}>
<Table variant='simple'  >
<TableCaption borderTop={"1px solid #161616"} bgColor={"#f9a526"} color={"black"} >There Are {count} Outlets</TableCaption>
<Thead bgColor={"black"}>
  <Tr  >
    <Th  color={"#f9a526"}>#</Th>
    <Th color={"#f9a526"}>Name</Th>
    <Th color={"#f9a526"}>Landmark</Th>
    <Th color={"#f9a526"}>City</Th>
    <Th color={"#f9a526"}>contact</Th>

    <Th color={"#f9a526"} >Action</Th>
  </Tr>
</Thead>
<Tbody>
{outlet?.map((e,i)=>{
    return <Tr key={e?._id} >
        <Td> {i+1} </Td>
        <Td>{e?.name}</Td>
        <Td> {e?.landmark}</Td>
        <Td> {e?.city?.name} </Td>
        <Td> {e?.contact} </Td>

        <Td>  
            <ButtonGroup>
            <Button leftIcon={<ViewIcon/>} bgColor={"black"} _hover={{bgColor:"#f9a526",color:"black"}} variant="solid" color="#f9a526" onClick={()=>navigate(`/admin/outlet/${e._id}`)}>View</Button>
            <Button leftIcon={<BiEditAlt/>} border="1px solid #f9a526" variant={"outline"}  _hover={{bgColor:"#f9a526",color:"black"}}  onClick={()=>navigate(`/admin/outlet/edit/${e._id}`)}>Edit</Button>
            <DeleteBtn handleDelete={()=>handleDelete(e._id)}/>
            </ButtonGroup>
        </Td>
    </Tr>
})}
</Tbody>
</Table>
</TableContainer>
<br />
<Flex justifyContent={"center"}>
{flag&&<div>
      <Button  border="1px solid #f9a526" bgColor={"black"} isDisabled={page===1} onClick={()=>setPage(page-1)}><BsArrowLeft color='#f9a526'/></Button>
      <Button>{page}</Button>
      <Button variant={"outline"} border="1px solid #f9a526" bgColor={"black"} isDisabled={page>=(count/12)} onClick={()=>setPage(page+1)}><BsArrowRight color='#f9a526'/></Button>
    </div>
    }
</Flex>
</Box>
  )
}

export default Outlet