import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Spinner,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import generateSlug from "../utility/generateSlug";
import Permalink from "./Permalink";

const EditEvent = () => {
  const { slugname } = useParams();
  let url = process.env.REACT_APP_DEV_URL;
  const [event, setEvent] = useState({});
  const [dataUrl, setDataUrl] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState([]);
  const [imgText, setimgText] = useState([]);
  const navigate = useNavigate();
  const [slug, setSlug] = useState("");
  const toast = useToast();

  const formData = new FormData();

  const handleChange = (e) => {
    let { name, value } = e.target;
    setEvent({ ...event, [name]: value });
  };

  const handleImage = (index) => {
    let dup = [...event.image];
    dup.splice(index, 1);

    //remove img with text
    let dupimgText = [...event.image_alt];
    dupimgText.splice(index, 1);
    setEvent({ ...event, image: dup, image_alt: dupimgText });
  };

  const handleImageLocal = (index) => {
    // let dup = [...dataUrl];
    // dup.splice(index, 1);
    // setDataUrl(dup);
    setImage((prev) => prev.filter((_, i) => i !== index));
    setDataUrl((prev) => prev.filter((_, i) => i !== index));
    setimgText((prev) => prev.filter((_, i) => i !== index));
  };
  const handleImgTex = (e, i) => {
    let imageText = [...imgText];
    imageText[i] = e.target.value;
    setimgText(imageText);
  };
  const handleImgTextData = (e, i) => {
    let dup = [...event.image_alt];
    dup[i] = e.target.value;
    setEvent({ ...event, image_alt: dup });
  };
  const handleFileChanger = (e) => {
    let file = e.target.files[0];
    console.log(file);
    // setImage(file);
    setImage([...image, file]);
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        // setDataUrl(reader.result);
        setDataUrl([...dataUrl, reader.result]);
      };
      reader.readAsDataURL(file);
    }
  };

  const getData = async () => {
    try {
      let data = await fetch(`${url}/event/${slugname}`);
      console.log("Fetch Data", data);
      data = await data.json();
      console.log("Slug wise Data Fecth", data);
      setEvent(data.data);
      setSlug(data.data?.slug);
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdate = async (imageUrl) => {
    setIsLoading(true);
    if (!slug) {
      toast({
        title: "Slug not add",
        description: "slug are required.",
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }
    let dup = { ...event };
    if (imageUrl) {
      dup.image = [...dup.image, ...imageUrl];
    }
    if (imgText.length > 0) {
      dup.image_alt = [...dup.image_alt, ...imgText];
    }
    let newSlug = generateSlug(slug);
    dup.slug = newSlug;
    try {
      let response = await fetch(`${url}/event/edit/${slugname}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dup),
      });
      let data = await response.json();
      console.log(data);
      if (response.status === 200) {
        toast({
          title: "Product has been successfully updated",
          description: data.msg,
          status: "success",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
        setIsLoading(false);
        navigate("/admin/event");
      } else {
        toast({
          title: "Error",
          description: data.msg,
          status: "error",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast({
        title: error.message,
        description: error,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const submitFile = async () => {
    if (!dataUrl.length) {
      return;
    }
    for (let x of image) {
      formData.append("event", x);
    }

    setIsLoading(true);
    try {
      let data = await axios.post(`${url}/event/image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(data.data.data);
      return data.data.data;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Box p="4">
      <Box>
        <Flex
          justifyContent={"space-around"}
          gap="40px"
          flexDirection={["column", "column", "column", "row", "row"]}
        >
          <Box
            backgroundColor={"white"}
            w={["100%", "100%", "100%", "100%", "100%"]}
            boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
            padding={"20px"}
            borderRadius={"20px"}
            border={"1px solid #f9a526"}
          >
            <FormControl isRequired>
              <FormLabel color={"#f9a526"}>Title</FormLabel>
              <Input
                variant={"flushed"}
                type="text"
                name="title"
                value={event?.title}
                onChange={(e) => handleChange(e)}
              />
            </FormControl>
            <Permalink slug={slug} setSlug={setSlug} folder={"event"} />
            <br />
            <FormControl isRequired>
              <FormLabel color={"#f9a526"}>Meta Title</FormLabel>
              <Input
                variant={"flushed"}
                type="text"
                name="meta_title"
                value={event?.meta_title}
                onChange={(e) => handleChange(e)}
              />
            </FormControl>
            <br />
            <FormControl isRequired>
              <FormLabel color={"#f9a526"}> Meta Description</FormLabel>
              <Textarea
                variant="flushed"
                name="meta_description"
                value={event?.meta_description}
                onChange={(e) => handleChange(e)}
                maxLength={"300"}
              />
            </FormControl>
            <br />
            <FormControl isRequired>
              <FormLabel color={"#f9a526"}>Place</FormLabel>
              <Input
                variant={"flushed"}
                type="text"
                name="place"
                value={event?.place}
                onChange={(e) => handleChange(e)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel color={"#f9a526"}>Date</FormLabel>
              <Input
                variant={"flushed"}
                type="date"
                name="date"
                value={event?.date}
                onChange={(e) => handleChange(e)}
              />
            </FormControl>
            <br />
            <FormControl isRequired>
              <FormLabel color={"#f9a526"}>Time</FormLabel>
              <Input
                variant={"flushed"}
                type="time"
                name="time"
                value={event?.time}
                onChange={(e) => handleChange(e)}
              />
            </FormControl>
            <Box></Box>
          </Box>
          <Box
            backgroundColor={"white"}
            boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
            padding={"20px"}
            borderRadius={"20px"}
            border={"1px solid #f9a526"}
          >
            <br />

            <br />
            <FormControl isRequired>
              <FormLabel color={"#f9a526"}>Description</FormLabel>
              <Textarea
                variant="flushed"
                name="description"
                value={event?.description}
                onChange={(e) => handleChange(e)}
                // maxLength={"300"}
              />
            </FormControl>
            <br />
            <FormControl isRequired>
              <FormLabel color={"#f9a526"}>Event Image</FormLabel>
              {dataUrl &&
                dataUrl.map((e, i) => {
                  return (
                    <>
                      <Flex gap="20px">
                        <Box>
                          <Image src={e} width="200px" />
                          <Input
                            value={imgText[i]}
                            onChange={(event) => handleImgTex(event, i)}
                          />
                        </Box>
                        <MdDelete
                          color="red"
                          size={"30px"}
                          onClick={() => handleImageLocal(i)}
                        />
                      </Flex>
                    </>
                  );
                })}

              {event?.image &&
                event?.image.map((e, i) => {
                  return (
                    <Flex gap="20px">
                      <Box>
                        <Image width="200px" src={`${url}/event/${e}`} />
                        <Input
                          value={event.image_alt[i]}
                          onChange={(event) => handleImgTextData(event, i)}
                        />
                      </Box>
                      <Box _hover={{ cursor: "pointer" }}>
                        <MdDelete
                          color="red"
                          size={"30px"}
                          onClick={() => handleImage(i)}
                        />
                      </Box>
                    </Flex>
                  );
                })}
              <br />
              <form encType="multipart/form-data">
                <input
                  required
                  type="file"
                  name="event"
                  onChange={(e) => handleFileChanger(e)}
                />
                <Text>
                  Note:Please Choose Image Less then 1000px width and 500px
                  width in PNG File
                </Text>
              </form>
              {/* <Text>
                <span style={{ fontWeight: "bold" }}>Note</span>:File Size
                Should Be Less than 500KB and 300x300px size will allow Only
              </Text> */}
            </FormControl>
          </Box>
        </Flex>
        <br />
        <center>
          <Button
            variant={"solid"}
            bgColor={"black"}
            color="#f9a526"
            _hover={{
              color: "black",
              bgColor: "#f9a526",
              border: "1px solid #f9a526",
            }}
            leftIcon={isLoading && <Spinner color="blue.500" />}
            // onClick={() => submitFile().then((res) => handleUpdate(res))}

            // onClick={()=>submitVariant()}
            onClick={() =>
              Promise.all([submitFile()])
                .then((result) =>
                  setTimeout(() => handleUpdate(result[0]), 4000)
                )
                .catch((err) => console.log(err))
            }
          >
            Save
          </Button>
        </center>
      </Box>
    </Box>
  );
};

export default EditEvent;
