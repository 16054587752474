import { Box, Button, Flex, FormControl, FormLabel, Image, Input, Spinner, Text, Textarea, useToast } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { MdDelete } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

const EditTestimonial = () => {
    const { id } = useParams();
    let url = process.env.REACT_APP_DEV_URL;
    const [test, setTest] = useState({});
    const [dataUrl, setDataUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [image, setImage] = useState({});
    const navigate = useNavigate();
    const toast = useToast();
  
    const formData = new FormData();
  
    const handleChange = (e) => {
      let { name, value } = e.target;
      setTest({ ...test, [name]: value });
    };
  

  

    const handleImage = (index) => {
      // let dup=[...product.image]
      //  dup.splice(index, 1);
      setTest({ ...test, image: "" });
    };
  
    const handleImageLocal = (index) => {
      // let dup=[...dataUrl]
      // dup.splice(index, 1);
      setDataUrl("");
    };
  

    const handleFileChanger = (e) => {
      let file = e.target.files[0];
      console.log(file);
      // setImage(file);
      setImage(file);
      if (file) {
        let reader = new FileReader();
        reader.onloadend = () => {
          // setDataUrl(reader.result);
          setDataUrl(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };

  
    const getData = async () => {
      try {
        let data = await fetch(`${url}/testimonial/${id}`);
        data = await data.json();
        console.log(data.data);
        setTest(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    const handleUpdate = async (imageUrl) => {
      let dup = { ...test };
      if (imageUrl) {
        dup.image = imageUrl;
      }
      try {
        let data = await fetch(`${url}/testimonial/edit/${id}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dup),
        });
        data = await data.json();
        console.log(data);
        setIsLoading(false);
        toast({
          title: "Product has been successfully updated",
          description: data.msg,
          status: "success",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
        navigate("/admin/testimonial");
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        toast({
          title: error.message,
          description: error,
          status: "error",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
      }
    };


    const submitFile = async () => {
      if (dataUrl === "") {
        return;
      }
  
      formData.append("testimonial", image);
  
      setIsLoading(true);
      try {
        let data = await axios.post(`${url}/testimonial/image`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(data.data.data);
        return data.data.data;
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      getData();
    }, []);
  return (
    <Box p="4">
        <Box>
      <Flex justifyContent={"space-around"} gap="40px" flexDirection={["column","column","column","row","row"]} >
        <Box
          backgroundColor={"white"}
          w={["100%","100%","100%","100%","100%"]}
          boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          padding={"20px"}
          borderRadius={"20px"}
          border={"1px solid #f9a526"}
        >
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Name</FormLabel>
            <Input
              variant={"flushed"}
              type="text"
              name="name"
              value={test?.name}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Caption</FormLabel>
            <Input
              variant={"flushed"}
              type="text"
              name="designation"
              value={test?.designation}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Description</FormLabel>
            <Textarea
              variant="flushed"
              name="description"
              value={test?.description}
              onChange={(e) => handleChange(e)}

              maxLength={"250"}
   

            />
          </FormControl>
          <br />
          <Box></Box>
        </Box>
        <Box
          backgroundColor={"white"}
          boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          padding={"20px"}
          borderRadius={"20px"}
          border={"1px solid #f9a526"}
        >
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Product Image</FormLabel>
            {dataUrl && (
              <>
                <Flex gap="20px">
                  <Image src={dataUrl} width="200px" />
                  <MdDelete
                    color="red"
                    size={"30px"}
                    onClick={() => handleImageLocal()}
                  />
                </Flex>
              </>
            )}
          
            {test?.image && (
              <Flex gap="20px">
                <Image width="200px" src={`${url}/testimonial/${test?.image}`} />
                <Box _hover={{ cursor: "pointer" }}>
                  <MdDelete
                    color="red"
                    size={"30px"}
                    onClick={() => handleImage()}
                  />
                </Box>
              </Flex>
            )}
            <br />
            <form encType="multipart/form-data">
              <input
                required
                type="file"
                name="testimonial"
                onChange={(e) => handleFileChanger(e)}
              />
            </form>
            <Text>
              <span style={{ fontWeight: "bold" }}>Note</span>:File Size Should
              Be Less than 500KB and 100x100px size will allow Only
            </Text>
          </FormControl>
        </Box>
      </Flex>
      <br />
      <center>
        <Button
          bgColor={"black"}
          color="#f9a526"
          _hover={{
            color: "black",
            bgColor: "#f9a526",
          }}
          leftIcon={isLoading && <Spinner color="blue.500" />}
          // onClick={() => submitFile().then((res) => handleUpdate(res))}

          // onClick={()=>submitVariant()}
          onClick={() =>
            Promise.all([submitFile()])
              .then((result) => handleUpdate(result[0]))
              .catch((err) => console.log(err))
          }
        >
          Save
        </Button>
      </center>
    </Box>
    </Box>
  )
}

export default EditTestimonial
