import {
  Box,
  Button,
  Flex,
  Image,
  SimpleGrid,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";

const ViewBlog = () => {
  const { slugname } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState({});
  const toast = useToast();
  let url = process.env.REACT_APP_DEV_URL;

  const getData = async () => {
    try {
      let res = await fetch(`${url}/blog/${slugname}`);
      let data = await res.json();
      console.log("json data", data.data);
      setEvent(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (slugname) => {
    try {
      let data = await fetch(`${url}/blog/delete/${slugname}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      data = await data.json();
      toast({
        title: "Blog has been Deleted",
        description: data.msg,
        status: "success",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
      navigate("/admin/blog");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Box textAlign={"left"} p="4">
      <Flex gap="20px">
        <Text fontSize={"xl"} fontWeight={"semibold"}>
          View Blog Details
        </Text>
        <Button
          borderRadius={"20px"}
          color={"#f9a526"}
          bgColor={"black"}
          _hover={{ color: "black", bgColor: "#f9a526" }}
          leftIcon={<BiEditAlt />}
          onClick={() => navigate(`/admin/blog/edit/${slugname}`)}
        >
          Edit
        </Button>
        <Button
          borderRadius={"20px"}
          color={"#f9a526"}
          bgColor={"black"}
          _hover={{ color: "black", bgColor: "#f9a526" }}
          leftIcon={<RiDeleteBin6Line />}
          onClick={() => handleDelete(slugname)}
        >
          Delete
        </Button>
      </Flex>
      <br />
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Title
      </Text>
      <Box
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {event?.title}
      </Box>
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Perma Link
      </Text>
      <Box
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {url + "/blog/" + event?.slug}
      </Box>
      <br />

      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Meta Title
      </Text>
      <Box
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {event?.meta_title}
      </Box>
      <br />

      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Meta Description
      </Text>
      <Textarea
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        value={event?.meta_description}
        fontSize={"medium"}
        readOnly
      />
      <br />
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Image
      </Text>
      {/* <SimpleGrid columns={[1,1,1,2,2]} rowGap={"9"} >
      {event?.image&&event?.image.map((e)=><Image  src={`${url}/event/${e}`}/>
      )}
      
      </SimpleGrid> */}
      <Image src={`${url}/blog/${event.image}`} width={"50%"} />
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Images Alt Text
      </Text>

      <Text
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {event.image_alt}
      </Text>
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Description
      </Text>
      <br />
      <Box
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        dangerouslySetInnerHTML={{ __html: event?.description }}
        fontSize={"medium"}
      ></Box>

      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Created at
      </Text>
      {event.createdAt && (
        <Box
          padding="10px 20px"
          width="50%"
          bgColor={"#eef1f4"}
          fontSize={"medium"}
        >
          {new Date(event.createdAt).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          })}
        </Box>
      )}
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Updated at
      </Text>
      {event.modifiedAt && (
        <Box
          padding="10px 20px"
          width="50%"
          bgColor={"#eef1f4"}
          fontSize={"medium"}
        >
          {new Date(event.modifiedAt).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          })}
        </Box>
      )}
    </Box>
  );
};

export default ViewBlog;
