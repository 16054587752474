import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Spinner,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import generateSlug from "../utility/generateSlug";
import Permalink from "./Permalink";

const AddEvent = () => {
  let url = process.env.REACT_APP_DEV_URL;
  const [product, setProduct] = useState({
    meta_title: "",
    meta_description: "",
    title: "",
    slug: "",
    description: "",
    image: [],
    image_alt: [],
    place: "",
    date: "",
    time: "",
  });
  const [dataUrl, setDataUrl] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState([]);
  const toast = useToast();
  const [slug, setSlug] = useState("");
  const navigate = useNavigate();

  const formData = new FormData();

  const handleChange = (e) => {
    let { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleFileChanger = (e) => {
    let file = e.target.files[0];
    console.log(file);
    // setImage(file);
    setImage([...image, file]);
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        // setDataUrl(reader.result);
        setDataUrl([...dataUrl, reader.result]);
      };
      reader.readAsDataURL(file);
    }
    e.target.value = "";
  };

  const handleImage = (index) => {
    let newArr = [...dataUrl];
    newArr.splice(index, 1);
    setDataUrl(newArr);
  };
  const handleImgAlt = (event, i) => {
    let imageAlt = [...product.image_alt];
    imageAlt[i] = event.target.value;
    setProduct({ ...product, image_alt: imageAlt });
  };
  const handleAdd = async (imageArr) => {
    let dup = { ...product };
    if (!slug) {
      toast({
        title: "Validation Error",
        description: "slug are required.",
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    if (imageArr?.length) {
      dup.image = [...dup.image, ...imageArr];
    }
    let newSlug = generateSlug(slug);
    dup.slug = newSlug;
    console.log("dup value", dup);
    try {
      let response = await fetch(`${url}/event/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dup),
      });
      let data = await response.json();
      // console.log(data);
      if (!response.ok) {
        throw new Error(data.msg || "Something went wrong");
      }
      setIsLoading(false);

      toast({
        title: "Event Added",
        description: data.msg,
        status: "success",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
      navigate("/admin/event");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast({
        title: "Error While Adding Event",
        description: error.message,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const submitFile = async () => {
    debugger;
    if (!dataUrl?.length) {
      return;
    }
    for (let x of image) {
      formData.append("event", x);
    }

    // formData.append("product", image);
    setIsLoading(true);
    try {
      let data = await axios.post(`${url}/event/image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(data.data.data);
      return data.data.data;
    } catch (error) {
      console.log(error);
      toast({
        title: "Error In Uploading Image",
        description: error.message,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    console.log(dataUrl);
  });
  return (
    <Box p="4">
      <Flex
        justifyContent={"space-around"}
        gap="40px"
        flexDirection={["column", "column", "column", "row", "row"]}
      >
        <Box
          backgroundColor={"white"}
          w={["100%", "100%", "100%", "100%", "100%"]}
          boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          padding={"20px"}
          borderRadius={"20px"}
          border={"1px solid #f9a526"}
        >
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Title</FormLabel>
            <Input
              required
              variant={"flushed"}
              type="text"
              name="title"
              value={product.title}
              onChange={(e) => {
                handleChange(e);
                setSlug(generateSlug(e.target.value));
              }}
            />
          </FormControl>
          <br />
          <Permalink slug={slug} folder={"event"} setSlug={setSlug} />
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Meta Title</FormLabel>
            <Input
              required
              variant={"flushed"}
              type="text"
              name="meta_title"
              value={product?.meta_title}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Meta Description</FormLabel>
            <Textarea
              variant="flushed"
              name="meta_description"
              value={product.meta_description}
              onChange={(e) => handleChange(e)}
              maxLength={"250"}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Place</FormLabel>
            <Input
              required
              variant={"flushed"}
              type="text"
              name="place"
              value={product.place}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Date</FormLabel>
            <Input
              required
              variant={"flushed"}
              type="date"
              name="date"
              value={product.date}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
        </Box>
        <Box
          backgroundColor={"white"}
          boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          padding={"20px"}
          w={["100%", "100%", "100%", "100%", "100%"]}
          borderRadius={"20px"}
          border={"1px solid #f9a526"}
        >
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Description</FormLabel>
            <Textarea
              variant="flushed"
              name="description"
              value={product.description}
              onChange={(e) => handleChange(e)}
              // maxLength={"250"}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Time</FormLabel>
            {/* <TimePicker
            required
            variant={"flushed"}
            type="time"
            name="time"
            value={product.time}
            // onChange={(e) => handleChange(e)}
            onSet={(val) => {
                alert('val:' + val.format12);
              }}
          /> */}
            <input
              variant={"flushed"}
              type="time"
              name="time"
              value={product.time}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Event Image</FormLabel>
            {dataUrl &&
              dataUrl?.map((e, i) => {
                return (
                  <div key={i}>
                    <Flex gap="20px">
                      <Box>
                        <Image src={e} width="200px" />
                        <Input
                          value={product.image_alt[i]}
                          onChange={(event) => handleImgAlt(event, i)}
                          placeholder="Add IMG ALT Text"
                        />
                      </Box>
                      <MdDelete
                        color="red"
                        size={"30px"}
                        onClick={() => handleImage()}
                      />
                    </Flex>
                  </div>
                );
              })}
            <br />
            <form encType="multipart/form-data">
              <input
                required
                type="file"
                name="event"
                onChange={(e) => handleFileChanger(e)}
              />
              <Text>
                Note:Please Choose Image Less then 1000px width and 500px width
                in PNG File
              </Text>
            </form>
            {/* <Text>
              <span style={{ fontWeight: "bold" }}>Note</span>:File Size Should
              Be Less than 500KB and 500x500px size will allow Only
            </Text> */}
          </FormControl>
        </Box>
      </Flex>
      <br />
      <center>
        <Button
          variant={"solid"}
          bgColor={"black"}
          color="#f9a526"
          _hover={{
            color: "black",
            bgColor: "#f9a526",
            border: "1px solid #f9a526",
          }}
          leftIcon={isLoading && <Spinner color="blue.500" />}
          // onClick={() => submitFile().then((res) => handleAdd(res))}
          onClick={() => {
            Promise.all([submitFile()])
              .then((res) => handleAdd(res[0]))
              .catch((err) => console.log(err));
          }}
          isDisabled={!product.title}
        >
          Add New
        </Button>
      </center>
    </Box>
  );
};

export default AddEvent;
