import { Route, Routes } from 'react-router-dom';
import './App.css';
import AllRoutes from './components/AllRoutes';
import Login from './components/Login';

function App() {
  return (
    <div className="App">
      <AllRoutes/>
    </div>
  );
}

export default App;
