import { Box, Button, Flex, Image, SimpleGrid, Text, Textarea, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { BiEditAlt } from 'react-icons/bi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';

const ViewEvent = () => {
    const { slugname} = useParams();
    const navigate = useNavigate();
    const [event, setEvent] = useState({});
    const toast = useToast();
    let url = process.env.REACT_APP_DEV_URL;
  
    const getData = async () => {
      try {
        let data = await fetch(`${url}/event/${slugname}`);
        data = await data.json();
        console.log(data.data);
        setEvent(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    const handleDelete = async (slugname) => {
      try {
        let data = await fetch(`${url}/event/delete/${slugname}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        });
        data = await data.json();
        toast({
          title: "Event has been Deleted",
          description: data.msg,
          status: "success",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
        navigate("/admin/event");
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      getData();
    }, []);
  return (
    <Box textAlign={"left"} p="4">
      <Flex gap="20px">
        <Text fontSize={"xl"} fontWeight={"semibold"}>
          View Event Details
        </Text>
        <Button
          borderRadius={"20px"}
          color={"#f9a526"}
          bgColor={"black"}
          _hover={{ color: "black", bgColor: "#f9a526" }}
          leftIcon={<BiEditAlt />}
          onClick={() => navigate(`/admin/event/edit/${slugname}`)}
        >
          Edit
        </Button>
        <Button
          borderRadius={"20px"}
          color={"#f9a526"}
          bgColor={"black"}
          _hover={{ color: "black", bgColor: "#f9a526" }}
          leftIcon={<RiDeleteBin6Line />}
          onClick={() => handleDelete(slugname)}
        >
          Delete
        </Button>
      </Flex>
      <br />
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Title
      </Text>
      <Box
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {event?.title}
      </Box>
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Perma Link
      </Text>
      <Box
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {url + "/event/" + event?.slug}
      </Box>
      <br/>

      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Place
      </Text>
      <Text
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {event?.place}
      </Text>
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Description
      </Text>
      <Textarea
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        value={event?.description}
        fontSize={"medium"}
      />
      <br />
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Meta Title
      </Text>
      <Box
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {event?.meta_title}
      </Box>
      <br />
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
       Meta Description
      </Text>
      <Textarea
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        value={event?.meta_description}
        fontSize={"medium"}
      />
      <br />
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Image
      </Text>
      <SimpleGrid columns={[1,1,1,2,2]} rowGap={"9"} >
      {event?.image&&event?.image.map((e)=><Image  src={`${url}/event/${e}`}/>
      )}
      
      </SimpleGrid>
      {/* <Image src={`${url}/event/${e}`}/> */}
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Images Alt Text
      </Text>
      {event?.image_alt?.map((e) => (
        <Text
          padding="10px 20px"
          width="50%"
          bgColor={"#eef1f4"}
          fontSize={"medium"}
        >
          {e}
        </Text>
      ))}

      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Created at
      </Text>
      {event.createdAt && (
        <Box
          padding="10px 20px"
          width="50%"
          bgColor={"#eef1f4"}
          fontSize={"medium"}
        >
          {new Date(event.createdAt).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          })}
        </Box>
      )}
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Updated at
      </Text>
      {event.modifiedAt && (
        <Box
          padding="10px 20px"
          width="50%"
          bgColor={"#eef1f4"}
          fontSize={"medium"}
        >
          {new Date(event.modifiedAt).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          })}
        </Box>
      )}
    </Box>
  )
}

export default ViewEvent