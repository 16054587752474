import { Box, Button, Flex, FormControl, FormLabel, Image, Spinner, Textarea, useToast } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { MdDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const ProductText = () => {
    const navigate = useNavigate();
    const toast = useToast();
    let url = process.env.REACT_APP_DEV_URL;
  const [dataUrl, setDataUrl] = useState("");
  const [image, setImage] = useState({});
  const [isLoading, setIsLoading] = useState(false);

    const [detail, setDetail] = useState({
    text:"",
    image:""
    });

  const formData = new FormData();

    const handleChange = (e) => {
    const { name, value } = e.target;
    setDetail({ ...detail, [name]: value });
    };

    const handleImage = (index) => {
      // let dup=[...product.image]
      //  dup.splice(index, 1);
      setDetail({ ...detail, image: "" });
    };
  
    const handleImageLocal = (index) => {
      setDataUrl("");
    };

    const submitFile = async () => {
      if (dataUrl === "") {
        return;
      }
  
      formData.append("product", image);
  
      setIsLoading(true);
      try {
        let data = await axios.post(`${url}/product/banner`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(data.data.data);
        return data.data.data;
      } catch (error) {
        console.log(error);
      }
    };
    const editText = async (imageUrl) => {
      let dup={...detail}
      if(imageUrl){
        dup.image=imageUrl
      }
      try {
        let data = await fetch(`${url}/product/text/edit/${detail._id}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dup),
        });
        data = await data.json();
        console.log(data);
        toast({
          title: "Product Page Edited",
          description: data.msg,
          status: "success",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
        navigate("/admin/page");
      } catch (error) {
        toast({
          title: "Error In Uploading Updates",
          description: error.message,
          status: "error",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
      }
    };

    const handleFileChanger = (e) => {
      let file = e.target.files[0];
      console.log(file);
      // setImage(file);
      setImage(file);
      if (file) {
        let reader = new FileReader();
        reader.onloadend = () => {
          // setDataUrl(reader.result);
  
          setDataUrl(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
  
    const getText = async () => {
      try {
        let data = await fetch(`${url}/product/text/view`);
        data = await data.json();
        console.log(data.data);
        setDetail(data.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
        getText();
    }, []);
  return (
    <Box p="4">
        <div>
      <Box
        backgroundColor={"white"}
        boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
        padding={"20px"}
        borderRadius={"20px"}
      >
         <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Banner Image</FormLabel>
            {dataUrl && (
              <>
                <Flex gap="20px">
                  <Image src={dataUrl}  />
                  <MdDelete
                    color="red"
                    size={"30px"}
                    onClick={() => handleImageLocal()}
                  />
                </Flex>
              </>
            )}
          
            {detail?.image && (
              <Flex gap="20px">
                <Image  src={`${url}/product/${detail?.image}`} />
                <Box _hover={{ cursor: "pointer" }}>
                  <MdDelete
                    color="red"
                    size={"30px"}
                    onClick={() => handleImage()}
                  />
                </Box>
              </Flex>
            )}
            <br />
            <form encType="multipart/form-data">
              <input
                required
                type="file"
                name="product"
                onChange={(e) => handleFileChanger(e)}
              />
            </form>
           
          </FormControl>
        <FormControl>
          <FormLabel color={"#f9a526"}>Text</FormLabel>
          <Textarea
            value={detail?.text}
            name="text"
            onChange={(e) => handleChange(e)}
          />
        </FormControl>
        <br />
        <Box>
          <Button
            variant={"solid"}
            bgColor={"black"}
            color="#f9a526"
            _hover={{
            color: "black",
            bgColor: "#f9a526",
            border: "1px solid #f9a526",
            }}
          leftIcon={isLoading && <Spinner color="blue.500" />}

            onClick={() => 
              Promise.all([submitFile()])
              .then((result) =>setTimeout(()=>editText(result[0]),3000)
               )
              .catch((err) => console.log(err))
            }
          >
            Save Text
          </Button>
        </Box>
      </Box>
    </div>
    </Box>
  )
}

export default ProductText