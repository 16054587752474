import { Box, Button, Flex, FormControl, FormLabel, Image, Input, Spinner, Text, Textarea, useToast } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const AddTestimonial = () => {
  let url = process.env.REACT_APP_DEV_URL;
  const [test, setTest] = useState({
    name: "",
    description: "",
    image: "",
    designation: "",
  });
  const [dataUrl, setDataUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState({});
  const toast = useToast();
  const navigate = useNavigate();

  const formData = new FormData();

  const handleChange = (e) => {
    let { name, value } = e.target;
    setTest({ ...test, [name]: value });
  };

  const handleFileChanger = (e) => {
    let file = e.target.files[0];
    console.log(file);
    // setImage(file);
    setImage(file);
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        // setDataUrl(reader.result);
        setDataUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
    e.target.value = "";
  };

  const handleImage = (index) => {
    // let newArr = [...dataUrl];
    // newArr.splice(index, 1);
    setDataUrl("");
  };

  const handleAdd = async (imageArr) => {
    let dup = { ...test };
    if (imageArr?.length > 0) {
      dup.image = imageArr;
    }
    try {
      let data = await fetch(`${url}/testimonial/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dup),
      });
      data = await data.json();
      // console.log(data);
      setIsLoading(false);
      toast({
        title: "Testimonial Added",
        description: data.msg,
        status: "success",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
      navigate("/admin/testimonial");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast({
        title: "Error While Creating Account",
        description: error.message,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const submitFile = async () => {
    debugger;
    if (dataUrl === "") {
      return;
    }

    formData.append("testimonial", image);

    // formData.append("product", image);
    setIsLoading(true);
    try {
      let data = await axios.post(`${url}/testimonial/image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(data.data.data);
      return data.data.data;
    } catch (error) {
      console.log(error);
      toast({
        title: "Error In Uploading Image",
        description: error.message,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return <Box p="4">
    <Box>
      <Flex justifyContent={"space-around"} gap="40px"  flexDirection={["column","column","column","row","row"]}>
        <Box
          backgroundColor={"white"}
          w={["100%","100%","100%","100%","100%"]}
          boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          padding={"20px"}
          borderRadius={"20px"}
        >
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Name</FormLabel>
            <Input
              required
              variant={"flushed"}
              type="text"
              name="name"
              value={test.name}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Designation</FormLabel>
            <Input
              required
              variant={"flushed"}
              type="text"
              name="designation"
              value={test?.designation}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Description</FormLabel>
            <Textarea
              variant="flushed"
              name="description"
              value={test.description}
              onChange={(e) => handleChange(e)}

              maxLength={"250"}
            

            />
          </FormControl>
          <br />
          <br />
        </Box>
        <Box
          backgroundColor={"white"}
          boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          padding={"20px"}
          w={["100%","100%","100%","100%","100%"]}
          borderRadius={"20px"}
        >
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Product Image</FormLabel>
            {dataUrl && (
                  <>
                    <Flex gap="20px">
                      <Image src={dataUrl} width="200px" />
                      <MdDelete
                        color="red"
                        size={"30px"}
                        onClick={() => handleImage()}
                      />
                    </Flex>
                  </>
            )}
            <br />
            <form encType="multipart/form-data">
              <input
                required
                type="file"
                name="product"
                onChange={(e) => handleFileChanger(e)}
              />
            </form>
            <Text>
              <span style={{ fontWeight: "bold" }}>Note</span>:File Size Should
              Be Less than 500KB and 100x100px size will allow Only
            </Text>
          </FormControl>
        </Box>
      </Flex>
      <br />
      <center>
        <Button
          variant={"solid"}
          bgColor={"black"}
            color="#f9a526"
            _hover={{
            color: "black",
            bgColor: "#f9a526",
            border: "1px solid #f9a526",
            }}
          leftIcon={isLoading && <Spinner color="blue.500" />}
          // onClick={() => submitFile().then((res) => handleAdd(res))}
          onClick={()=>{
            Promise.all([submitFile()])
            .then(res=>handleAdd(res[0]))
            .catch(err=>console.log(err))
          }}
          isDisabled={!test.name}
        >
          Add New
        </Button>
      </center>
    </Box>
  </Box>;
};

export default AddTestimonial;
