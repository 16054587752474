import { createContext, useEffect, useState } from "react";

const userContext = createContext();

export const User = ({ children }) => {
  const [userData, setUserData] = useState({});
  let url=process.env.REACT_APP_DEV_URL
  const getUserData = async () => {
    try {
      let data = await fetch(
        `${url}/user/${JSON.parse(localStorage.getItem("user"))}`
      );
      data = await data.json();
      console.log("user",data.data);
      if(data.data.image){
        setUserData(data.data);
      }
    else{
      setUserData({
          ...data.data,
          image:
            "https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-High-Quality-Image.png",
        })
    }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUserData();
    setTimeout(()=>{
      localStorage.removeItem("user")
      window.location.reload()
    },2*60*60*1000)
  }, []);
  return (
    <userContext.Provider value={{ userData, setUserData }}>
      {children}
    </userContext.Provider>
  );
};

export default userContext;
