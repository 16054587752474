import {
  Box,
  Button,
  Flex,
  Image,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";

const ViewProduct = () => {
  const { slugname,id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const toast = useToast();
  let url = process.env.REACT_APP_DEV_URL;

  const getData = async () => {
    try {
      let data = await fetch(`${url}/product/${slugname}`);
      data = await data.json();
      console.log(data.data);
      setProduct(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (id) => {
    try {
      let data = await fetch(`${url}/product/delete/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      data = await data.json();
      toast({
        title: "Product has been Deleted",
        description: data.msg,
        status: "success",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
      navigate("/admin/product");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Box textAlign={"left"} p="4">
      <Flex gap="20px">
        <Text fontSize={"xl"} fontWeight={"semibold"}>
          View Product Details
        </Text>
        <Button
          borderRadius={"20px"}
          color={"#f9a526"}
          bgColor={"black"}
          _hover={{ color: "black", bgColor: "#f9a526" }}
          leftIcon={<BiEditAlt />}
          onClick={() => navigate(`/admin/product/edit/${slugname}`)}
        >
          Edit
        </Button>
        <Button
          borderRadius={"20px"}
          color={"#f9a526"}
          bgColor={"black"}
          _hover={{ color: "black", bgColor: "#f9a526" }}
          leftIcon={<RiDeleteBin6Line />}
          onClick={() => handleDelete(id)}
        >
          Delete
        </Button>
      </Flex>
      <br />
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Name
      </Text>
      <Box
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {product.name}
      </Box>
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Perma Link
      </Text>
      <Box
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {url + "/product/" + product?.slug}
      </Box>
      <br/>
      
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Meta Title
      </Text>
      <Box
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {product?.meta_title}
      </Box>
      <br />
      
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Meta Description
      </Text>
      <Box
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {product.meta_description}
      </Box>
      <br />
      {/* <Text fontWeight={"semibold"} fontSize={"xl"}>
        Caption
      </Text>
      <Text
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {product?.caption}
      </Text>
      <br /> */}
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Category
      </Text>
      <Text
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {product.category?.name}
      </Text>
      <br />
      
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Description
      </Text>
      <Textarea
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        value={product.description}
        fontSize={"medium"}
      />
      <br />
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Image
      </Text>
      {/* <SimpleGrid columns={[1,1,1,2,2]} rowGap={"9"} >
      {product?.image&&product?.image.map((e)=><Image  src={`${url}/product/${e}`}/>
      )}
      
      </SimpleGrid> */}
      <Image src={`${url}/product/${product?.image}`}/>
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Image Alt Name
      </Text>
      <Box
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {product?.img_alt}
      </Box>
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Available Variants
      </Text>
      <Flex gap="20px" wrap={"wrap"}>

      {product?.available&&product?.available.map((e,i)=>
      <Box key={i} w={"150px"}>
      <Image  src={`${url}/variant/${e}`}/>
      <Text>{product?.variant[i]}</Text>
      </Box>

      )}
      
      </Flex>
     
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Created at
      </Text>
      {product.createdAt && (
        <Box
          padding="10px 20px"
          width="50%"
          bgColor={"#eef1f4"}
          fontSize={"medium"}
        >
          {new Date(product.createdAt).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          })}
        </Box>
      )}
      <br />
      <Text fontWeight={"semibold"} fontSize={"xl"}>
        Updated at
      </Text>
      {product.modifiedAt && (
        <Box
          padding="10px 20px"
          width="50%"
          bgColor={"#eef1f4"}
          fontSize={"medium"}
        >
          {new Date(product.modifiedAt).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          })}
        </Box>
      )}
    </Box>
  );
};

export default ViewProduct;
