import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdDelete, MdOutlineRemoveCircle } from "react-icons/md";
import { GrFormAdd } from "react-icons/gr";
import { IoIosRemove } from "react-icons/io";
import Permalink from "./Permalink";
import generateSlug from "../utility/generateSlug";

const EditProduct = () => {
  const { slugname } = useParams();
  let url = process.env.REACT_APP_DEV_URL;
  const [product, setProduct] = useState({});
  const [dataUrl, setDataUrl] = useState("");
  const [varUrl, setVarUrl] = useState([]);
  const [varImage, setVarImage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState({});
  const [category, setCategory] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();
  const [variantName, setVariantName] = useState([]);
  const [currentVarName, setCurrentVarName] = useState([]);
  const [slug, setSlug] = useState("");
  const formData = new FormData();

  const handleChange = (e) => {
    let { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleVarImage = (index) => {
    let dup = [...product.available];
    let dupVar = [...currentVarName];
    dup.splice(index, 1);
    dupVar.splice(index, 1);
    setProduct({ ...product, available: dup });
    setCurrentVarName(dupVar);
  };

  const handleVarLocal = (index) => {
    let dup = [...varUrl];
    let dupVar = [...variantName];

    dup.splice(index, 1);
    dupVar.splice(index, 1);
    setVarUrl(dup);
    setVariantName(variantName);
  };

  const handleImage = (index) => {
    // let dup=[...product.image]
    //  dup.splice(index, 1);
    setProduct({ ...product, image: "" });
  };
  const handleImgAlt = (e) => {
    let imgAlt = e.target.value;
    setProduct({ ...product, img_alt: imgAlt });
  };
  const handleImageLocal = (index) => {
    setDataUrl("");
  };

  const handleVarChanger = (e) => {
    let file = e.target.files[0];
    console.log(file);
    // setImage(file);
    // setImage(file)
    setVarImage([...varImage, file]);
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        // setDataUrl(reader.result);
        // setDataUrl(reader.result)
        setVariantName([...variantName, ""]);
        setVarUrl([...varUrl, reader.result]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileChanger = (e) => {
    let file = e.target.files[0];
    console.log(file);
    // setImage(file);
    setImage(file);
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        // setDataUrl(reader.result);

        setDataUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const getCategory = async () => {
    try {
      let data = await fetch(`${url}/category`);
      data = await data.json();
      setCategory(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    try {
      let data = await fetch(`${url}/product/${slugname}`);
      data = await data.json();
      console.log(data.data);
      setProduct(data.data);
      // setSpec(data.data.specification);
      // data.data.variant&&setVariantName(data.data?.variant)
      data.data.variant && setCurrentVarName(data.data?.variant);
      setSlug(data.data?.slug);
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdate = async (imageUrl, variantUrl) => {
    if (!slug) {
      toast({
        title: "Product Not Add ! ",
        description: "Permalink is Required",
        status: "error",
        position: "top",
        duration: 7000,
        isClosable: true,
      });
      return;
    }
    let dup = { ...product };
    if (imageUrl) {
      dup.image = imageUrl;
    }
    if (variantUrl) {
      dup.available = [...dup.available, ...variantUrl];
      console.log(variantName);
    }
    dup.variant = [...currentVarName, ...variantName];
    let newSlug = generateSlug(slug);
    dup.slug = newSlug;
    console.log(dup);
    try {
      let response = await fetch(`${url}/product/edit/${slugname}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dup),
      });
      let data = await response.json();
      console.log(data);
      setIsLoading(false);
      if (response.status === 200) {
        toast({
          title: "Product has been successfully updated",
          description: data.msg,
          status: "success",
          position: "top",
          duration: 9000,
          isClosable: true,
        });

        navigate("/admin/product");
      } else {
        toast({
          title: "Error",
          description: data.msg,
          status: "error",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast({
        title: error.message,
        description: error,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const submitVariant = async () => {
    debugger;
    if (!varUrl.length) {
      return;
    }

    for (let x of varImage) {
      await formData.append("variant", x);
    }

    setIsLoading(true);
    try {
      let data = await axios.post(`${url}/product/variant`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(data.data);
      setIsLoading(false);
      return data.data.data;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const submitFile = async () => {
    if (dataUrl === "") {
      return;
    }

    formData.append("product", image);

    setIsLoading(true);
    try {
      let data = await axios.post(`${url}/product/image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(data.data.data);
      setIsLoading(false);
      return data.data.data;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
    getCategory();
  }, []);
  return (
    <Box p="4">
      <Flex
        justifyContent={"space-around"}
        gap="40px"
        flexDirection={["column", "column", "column", "row", "row"]}
      >
        <Box
          backgroundColor={"white"}
          w={["100%", "100%", "100%", "100%", "100%"]}
          boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          padding={"20px"}
          borderRadius={"20px"}
          border={"1px solid #f9a526"}
        >
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Name</FormLabel>
            <Input
              variant={"flushed"}
              type="text"
              name="name"
              value={product?.name}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <Permalink slug={slug} setSlug={setSlug} folder={"product"} />
          <br />
          {/* <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Caption</FormLabel>
            <Input
              variant={"flushed"}
              type="text"
              name="caption"
              value={product?.caption}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br /> */}
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Meta Title</FormLabel>
            <Input
              variant={"flushed"}
              type="text"
              name="meta_title"
              value={product?.meta_title}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Meta Description</FormLabel>
            <Textarea
              variant="flushed"
              name="meta_description"
              value={product?.meta_description}
              onChange={(e) => handleChange(e)}
              maxLength={850}
            />
          </FormControl>
          <br />
          <FormControl>
            <FormLabel color={"#f9a526"}>Category</FormLabel>
            <select
              style={{
                width: "200px",
                padding: "10px",
                border: "1px solid #f9a526",
                borderRadius: "20px",
              }}
              onChange={(e) =>
                setProduct({ ...product, category: e.target.value })
              }
            >
              <option value={product?.category?._id}>
                {product?.category?.name}
              </option>
              {category &&
                category.map((e) => (
                  <option
                    style={{
                      width: "200px",
                      padding: "10px",
                      margin: "10px",
                      border: "1px solid #f9a526",
                      borderRadius: "20px",
                    }}
                    value={e?._id}
                  >
                    {e.name}
                  </option>
                ))}
            </select>
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Description</FormLabel>
            <Textarea
              variant="flushed"
              name="description"
              value={product?.description}
              onChange={(e) => handleChange(e)}
              maxLength={850}
            />
          </FormControl>
          <br />
          <Box></Box>
        </Box>
        <Box
          backgroundColor={"white"}
          boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          padding={"20px"}
          borderRadius={"20px"}
          border={"1px solid #f9a526"}
        >
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Product Image</FormLabel>
            {dataUrl && (
              <>
                <Flex gap="20px">
                  <Box>
                    <Image src={dataUrl} width="200px" />
                    <Input
                      value={product?.img_alt}
                      onChange={(e) => handleImgAlt(e)}
                    />
                  </Box>
                  <MdDelete
                    color="red"
                    size={"30px"}
                    onClick={() => handleImageLocal()}
                  />
                </Flex>
              </>
            )}

            {product?.image && (
              <Flex gap="20px">
                <Box>
                  <Image
                    width="200px"
                    src={`${url}/product/${product?.image}`}
                  />
                  <Input
                    value={product?.img_alt}
                    onChange={(e) => handleImgAlt(e)}
                  />
                </Box>
                <Box _hover={{ cursor: "pointer" }}>
                  <MdDelete
                    color="red"
                    size={"30px"}
                    onClick={() => handleImage()}
                  />
                </Box>
              </Flex>
            )}
            <br />
            <form encType="multipart/form-data">
              <input
                required
                type="file"
                name="product"
                onChange={(e) => handleFileChanger(e)}
              />
            </form>
            <Text>
              <span style={{ fontWeight: "bold" }}>Note</span>:PNG File will
              allow Only
            </Text>
          </FormControl>
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Available Variants</FormLabel>
            {varUrl &&
              varUrl.map((e, i) => {
                return (
                  <>
                    <Flex gap="20px">
                      <Box>
                        <Image src={e} width="200px" />
                        <br />
                        <Input
                          value={variantName[i]}
                          onChange={(event) => {
                            let names = [...variantName];
                            names[i] = event.target.value;
                            setVariantName(names);
                          }}
                        />
                      </Box>

                      <MdDelete
                        color="red"
                        size={"30px"}
                        onClick={() => handleVarLocal(i)}
                      />
                    </Flex>
                  </>
                );
              })}
            {product?.available &&
              product.available.map((e, i) => {
                return (
                  <>
                    <Flex gap="0px">
                      <Box>
                        <Image width="200px" src={`${url}/variant/${e}`} />
                        <Input
                          value={currentVarName[i]}
                          onChange={(event) => {
                            let names = [...currentVarName];
                            names[i] = event.target.value;
                            setCurrentVarName(names);
                          }}
                        />
                        <br />
                      </Box>
                      <MdDelete
                        color="red"
                        size={"30px"}
                        onClick={() => handleVarImage(i)}
                      />
                    </Flex>
                  </>
                );
              })}
            <br />
            <form encType="multipart/form-data">
              <input
                required
                type="file"
                name="variant"
                onChange={(e) => handleVarChanger(e)}
              />
            </form>
            <Text>
              <span style={{ fontWeight: "bold" }}>Note</span>:File Size Should
              Be Less than 500KB and 200x200px size will allow Only
            </Text>
          </FormControl>
        </Box>
      </Flex>
      <br />
      <center>
        <Button
          bgColor={"black"}
          color="#f9a526"
          _hover={{
            color: "black",
            bgColor: "#f9a526",
          }}
          leftIcon={isLoading && <Spinner color="blue.500" />}
          // onClick={() => submitFile().then((res) => handleUpdate(res))}
          // onClick={()=>submitVariant()}
          onClick={() =>
            Promise.all([submitFile(), submitVariant()])
              .then((result) =>
                setTimeout(() => handleUpdate(result[0], result[1]), 5000)
              )
              .catch((err) => console.log(err))
          }
        >
          Save
        </Button>
      </center>
    </Box>
  );
};

export default EditProduct;
