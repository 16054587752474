import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Spinner,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import generateSlug from "../utility/generateSlug";
import Permalink from "./Permalink";

const AddProduct = () => {
  let url = process.env.REACT_APP_DEV_URL;
  const [product, setProduct] = useState({
    name: "",
    slug: "",
    meta_title: "",
    meta_description: "",
    description: "",
    image: "",
    img_alt: "",
    caption: "",
    category: "",
    available: [],
  });
  const [dataUrl, setDataUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState({});
  const [category, setCategory] = useState([]);
  const toast = useToast();
  const navigate = useNavigate();
  const [varUrl, setVarUrl] = useState([]);
  const [varImage, setVarImage] = useState([]);
  const [variantName, setVariantName] = useState([]);
  const [slug, setSlug] = useState("");

  const formData = new FormData();

  const handleChange = (e) => {
    let { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleVarImage = (index) => {
    let dup = [...product.available];
    dup.splice(index, 1);
    setProduct({ ...product, available: dup });
  };

  const handleVarLocal = (index) => {
    let dup = [...varUrl];
    dup.splice(index, 1);
    setVarUrl(dup);
  };

  const handleVarChanger = (e) => {
    let file = e.target.files[0];
    console.log(file);
    // setImage(file);
    // setImage(file)
    setVarImage([...varImage, file]);
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        // setDataUrl(reader.result);
        // setDataUrl(reader.result)
        setVarUrl([...varUrl, reader.result]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileChanger = (e) => {
    let file = e.target.files[0];
    console.log(file);
    // setImage(file);
    setImage(file);
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        // setDataUrl(reader.result);
        setDataUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
    e.target.value = "";
  };

  const handleImage = (index) => {
    // let newArr = [...dataUrl];
    // newArr.splice(index, 1);
    setDataUrl("");
  };
  const handleImgAlt = (e) => {
    let imgAlt = e.target.value;
    setProduct({ ...product, img_alt: imgAlt });
  };
  const getCategory = async () => {
    try {
      let data = await fetch(`${url}/category`);
      data = await data.json();
      setCategory(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAdd = async (imageArr, varArr) => {
    setIsLoading(true);

    let dup = { ...product };
    if (imageArr?.length > 0) {
      dup.image = imageArr;
    }
    if (varArr?.length) {
      dup.available = varArr;
    }
    dup.variant = [...variantName];
    let newSlug = generateSlug(slug);
    dup.slug = newSlug;
    try {
      let response  = await fetch(`${url}/product/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dup),
      });
     let data = await response.json();
      // console.log(data);
      setIsLoading(false);
      toast({
        title: response.ok ? "Product Added" : "Error While Adding Product",
        description: data.msg,
        status: response.ok ? "success" : "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
      if (response.ok) {
        navigate("/admin/product");
    }
     
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast({
        title: "Error While Adding Product",
        description: error.message,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const submitVariant = async () => {
    debugger;
    if (!varUrl.length) {
      return;
    }
    for (let x of varImage) {
      formData.append("variant", x);
    }

    setIsLoading(true);
    try {
      let data = await axios.post(`${url}/product/variant`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(data.data);
      return data.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const submitFile = async () => {
    debugger;
    if (dataUrl === "") {
      return;
    }

    formData.append("product", image);

    // formData.append("product", image);
    setIsLoading(true);
    try {
      let data = await axios.post(`${url}/product/image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(data.data.data);
      return data.data.data;
    } catch (error) {
      console.log(error);
      toast({
        title: "Error In Uploading Image",
        description: error.message,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    getCategory();
    console.log(dataUrl);
  }, []);
  return (
    <Box p="4">
      <Flex
        justifyContent={"space-around"}
        gap="40px"
        flexDirection={["column", "column", "column", "row", "row"]}
      >
        <Box
          backgroundColor={"white"}
          w={["100%", "100%", "100%", "100%", "100%"]}
          boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          padding={"20px"}
          borderRadius={"20px"}
        >
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Name</FormLabel>
            <Input
              required
              variant={"flushed"}
              type="text"
              name="name"
              value={product.name}
              onChange={(e) => {
                handleChange(e);
                setSlug(generateSlug(e.target.value));
              }}
            />
          </FormControl>
          <br />
          <Permalink slug={slug} folder={"product"} setSlug={setSlug} />
          {/* <FormControl isRequired>
            <FormLabel color={"#f9a526"}> Caption</FormLabel>
            <Input
              required
              variant={"flushed"}
              type="text"
              name="caption"
              value={product.caption}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br /> */}
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Meta Title</FormLabel>
            <Input
              required
              variant={"flushed"}
              type="text"
              name="meta_title"
              value={product.meta_title}
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Meta Description</FormLabel>
            <Textarea
              variant="flushed"
              name="meta_description"
              value={product.meta_description}
              onChange={(e) => handleChange(e)}
              maxLength={850}
            />
          </FormControl>
          <FormControl>
            <FormLabel color={"#f9a526"}>Category</FormLabel>
            <select
              style={{
                width: "200px",
                padding: "10px",
                margin: "10px",
                border: "1px solid #f9a526",
                borderRadius: "20px",
              }}
              onChange={(e) =>
                setProduct({ ...product, category: e.target.value })
              }
            >
              <option>Select</option>
              {category &&
                category.map((e) => <option value={e?._id}>{e.name}</option>)}
            </select>
          </FormControl>
          <br />
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Description</FormLabel>
            <Textarea
              variant="flushed"
              name="description"
              value={product.description}
              onChange={(e) => handleChange(e)}
              maxLength={850}
            />
          </FormControl>
          <br />
          <br />
        </Box>
        <Box
          backgroundColor={"white"}
          boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          padding={"20px"}
          w={["100%", "100%", "100%", "100%", "100%"]}
          borderRadius={"20px"}
        >
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Product Image</FormLabel>
            {dataUrl && (
              <>
                <Flex gap="20px">
                  <Box>
                    <Image src={dataUrl} width="200px" />
                    <Input
                      value={product.img_alt}
                      onChange={(e) => handleImgAlt(e)}
                      placeholder="plz add Product alt name"
                    />
                  </Box>
                  <MdDelete
                    color="red"
                    size={"30px"}
                    onClick={() => handleImage()}
                  />
                </Flex>
              </>
            )}
            <br />
            <form encType="multipart/form-data">
              <input
                required
                type="file"
                name="product"
                onChange={(e) => handleFileChanger(e)}
              />
            </form>
            <Text>
              <span style={{ fontWeight: "bold" }}>Note</span>:PNG File will
              allow Only
            </Text>
          </FormControl>
          <FormControl isRequired>
            <FormLabel color={"#f9a526"}>Available Variants</FormLabel>
            {varUrl &&
              varUrl.map((e, i) => {
                return (
                  <>
                    <Flex gap="20px">
                      <Box>
                        <Image src={e} width="200px" />
                        <br />
                        <Input
                          value={variantName[i]}
                          onChange={(event) => {
                            let names = [...variantName];
                            names[i] = event.target.value;
                            setVariantName(names);
                          }}
                        />
                      </Box>
                      <MdDelete
                        color="red"
                        size={"30px"}
                        onClick={() => handleVarLocal(i)}
                      />
                    </Flex>
                  </>
                );
              })}
            <br />
            <form encType="multipart/form-data">
              <input
                required
                type="file"
                name="variant"
                onChange={(e) => handleVarChanger(e)}
              />
            </form>
            <Text>
              <span style={{ fontWeight: "bold" }}>Note</span>:File Size Should
              Be Less than 500KB and 200x200px size will allow Only
            </Text>
          </FormControl>
        </Box>
      </Flex>
      <br />
      <center>
        <Button
          variant={"solid"}
          bgColor={"black"}
          color="#f9a526"
          _hover={{
            color: "black",
            bgColor: "#f9a526",
            border: "1px solid #f9a526",
          }}
          leftIcon={isLoading && <Spinner color="blue.500" />}
          // onClick={() => submitFile().then((res) => handleAdd(res))}
          onClick={() => {
            Promise.all([submitFile(), submitVariant()])
              .then((res) => handleAdd(res[0], res[1]))
              .catch((err) => console.log(err));
          }}
          isDisabled={!product.name}
        >
          Add New
        </Button>
      </center>
    </Box>
  );
};

export default AddProduct;
