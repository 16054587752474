import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Catalogue = () => {
  const [topUrl, setTopUrl] = useState("");
  const [topImage, setTopImage] = useState({});
  const [detail, setDetail] = useState({});
  const [name,setName]=useState("")
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const url = process.env.REACT_APP_DEV_URL;


  const handleTopChanger = async (e) => {
    let file = e.target.files[0];
    console.log(file);
    setTopImage(file);
    setName(file.name)
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setTopUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const submitTopImage = async () => {
    let formData = new FormData();

    if (!topUrl) {
      return;
    }
    formData.append("catalogue", topImage);
    setIsLoading(true);
    try {
      let data = await axios.post(`${url}/catalogue/image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(data.data.data);
      setIsLoading(false);
      toast({
        title: "Image Uploaded Successfully",
        description: "",
        status: "success",
        position: "top",
        duration: 9000,
        isClosable: true,
      });

      // setDetail({ ...detail, top_image: data.data.data });
      // setAboutImage(data.data.data);
      return data.data.data;
    } catch (error) {
      console.log(error);
      toast({
        title: "Error In Uploading Image",
        description: error.message,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
      setTopUrl("");
    }
  };

  const editMenu = async (ui) => {
    debugger;
    let updatedMenu = { ...detail };
    if (ui) {
      updatedMenu = { ...updatedMenu, catalogue: ui };
    }

    setDetail(updatedMenu);
    try {
      let data = await fetch(`${url}/catalogue/edit/${detail._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedMenu),
      });
      data = await data.json();
      console.log(data);
      toast({
        title: "Catalogue Edited",
        description: data.msg,
        status: "success",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
      navigate("/admin");
    } catch (error) {
      toast({
        title: "Error In Uploading Updates",
        description: error.message,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };


  const handleImage = (index) => {
    // let dup=[...product.image]
    //  dup.splice(index, 1);
    setDetail({ ...detail, catalogue: "" });
  };

  const handleImageLocal = (index) => {
    setTopUrl("");
  };

  const getMenu = async () => {
    try {
      let data = await fetch(`${url}/catalogue`);
      data = await data.json();
      console.log(data.data[0]);
      setDetail(data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMenu();
  }, []);
  return (
    <Box p="4">
      <Box
        backgroundColor={"white"}
        boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
        padding={"20px"}
        borderRadius={"20px"}
      >
        <FormControl isRequired>
          <FormLabel color="#f9a526">Catalogue</FormLabel>
          {/* <Image src={topUrl} /> */}
          {topUrl && (
            <>
              <Flex gap="20px">
                {/* <Image src={topUrl} width="200px" />
                 */}
                 <Text>{name}</Text>
                <MdDelete
                  color="red"
                  size={"30px"}
                  onClick={() => handleImageLocal()}
                />
              </Flex>
            </>
          )}
          {/* <Image src={`${url}/catalogue/${detail?.top_image}`} /> */}
          {detail?.catalogue && (
            <Flex gap="20px">
              <Text>{detail?.catalogue}</Text>
              <Box _hover={{ cursor: "pointer" }}>
                <MdDelete
                  color="red"
                  size={"30px"}
                  onClick={() => handleImage()}
                />
              </Box>
            </Flex>
          )}
          <br />
          <form encType="multipart/form-data">
            <input
              required
              type="file"
              name="catalogue"
              onChange={(e) => handleTopChanger(e)}
            />
          </form>
        </FormControl>
      </Box>
      <Button
            mt="10px"
        variant={"solid"}
        bgColor={"black"}
        color="#f9a526"
        _hover={{
          color: "black",
          bgColor: "#f9a526",
          border: "1px solid #f9a526",
        }}
        leftIcon={isLoading && <Spinner color="blue.500" />}
        onClick={() =>
          Promise.all([submitTopImage()])
            .then((res) => setTimeout(()=> editMenu(res[0]),4000))
            .catch((err) => console.log(err))
        }
      >
        Save Catalogue
      </Button>
    </Box>
  );
};

export default Catalogue;
