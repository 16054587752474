import React, { useContext } from "react";
import userContext from "../context/userDetail";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Image,
  Text,
  transition,
} from "@chakra-ui/react";
import container from "../images/container.png";
import "../styles/profile.css";
import back from "../images/back.png"

const Profile = () => {
  const { userData } = useContext(userContext);
  const navigate = useNavigate();
  const url = process.env.REACT_APP_DEV_URL;

  return (
    <Box  bgColor={"#fba627"} height={"100%"} >
      <Flex
        position="relative"
        justifyContent="center"
        alignSelf={"center"}
        alignItems="center"
      >
        <Image src={container} />
        <Flex alignItems={"center"} direction={"column"}  className="profile">
        <Text fontSize={["large","x-large","x-large","xx-large","xx-large"]}  color={"#da4d59"}>
          {userData.name}
        </Text>
        <br />
        <Image width={["60%","60%","80%","80%","90%"]} borderRadius={"50%"} src={`${url}/user/${userData.image}`} />
        <Text fontSize={["large","x-large","x-large","xx-large","xx-large"]}  color={"#da4d59"}>
          {userData.email}
        </Text>
        <br />
        <Button
            variant="outline"
            onClick={() => navigate(`/admin/user/edit/${userData._id}`)}
            color={"#da4d59"}
          >
            Edit Profile
          </Button>
        </Flex>
      </Flex>
      {/* <Flex className="profile-avtar">
        <div>
          {userData.image ==
          "https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-High-Quality-Image.png" ? (
            <Avatar
              ml="4"
              size="2xl"
              name="anubra266"
              src={userData.image}
              cursor="pointer"
            />
          ) : (
            <Avatar
              ml="4"
              size="2xl"
              name="anubra266"
              src={`${url}/user/` + userData.image}
              cursor="pointer"
            />
          )}
          <Text>{userData.email}</Text>
          <Text>{userData.name}</Text>
          <Button
            variant="outline"
            onClick={() => navigate(`/admin/user/edit/${userData._id}`)}
          >
            Edit Profile
          </Button>
        </div>
      </Flex> */}
    </Box>
  );
};

export default Profile;
