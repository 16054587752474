import { Box, Button, Flex, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

const ViewInquiry = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [inquiry, setInquiry] = useState({});
    const toast = useToast();
    let url = process.env.REACT_APP_DEV_URL;
  
    const getData = async () => {
      try {
        let data = await fetch(`${url}/inquiry/${id}`);
        data = await data.json();
        console.log(data.data);
        setInquiry(data.data);
      } catch (error) {
        console.log(error);
      }
    };
   
    useEffect(() => {
      getData();
    }, []);
  return (
    <Box  textAlign={"left"} p="4">
    <Flex gap="20px">
      <Text fontSize={"xl"} fontWeight={"semibold"}>
        View Inquiry Details
      </Text>
      
    </Flex>
    <br />
    <br />
    <Text fontWeight={"semibold"} fontSize={"xl"}>
      Name
    </Text>
    <Box
      padding="10px 20px"
      width="50%"
      bgColor={"#eef1f4"}
      fontSize={"medium"}
    >
      {inquiry.name}
    </Box>
    <br />
    <Text fontWeight={"semibold"} fontSize={"xl"}>
      Inquiry Number
    </Text>
    <Text
      padding="10px 20px"
      width="50%"
      bgColor={"#eef1f4"}
      fontSize={"medium"}
    >
      {inquiry?.inquiry_number}
    </Text>
    <br />
    <Text fontWeight={"semibold"} fontSize={"xl"}>
      Contact
    </Text>
    <Text
      padding="10px 20px"
      width="50%"
      bgColor={"#eef1f4"}
      fontSize={"medium"}
    >
      {inquiry?.phone}
    </Text>
    <br />
    <Text fontWeight={"semibold"} fontSize={"xl"}>
      Email
    </Text>
    <Text
      padding="10px 20px"
      width="50%"
      bgColor={"#eef1f4"}
      fontSize={"medium"}
    >
      {inquiry?.email}
    </Text>
    <br />
    <Text fontWeight={"semibold"} fontSize={"xl"}>
      Message
    </Text>
    <Text
      padding="10px 20px"
      width="50%"
      bgColor={"#eef1f4"}
      fontSize={"medium"}
    >
      {inquiry?.message}
    </Text>
    <br />
    <Text fontWeight={"semibold"} fontSize={"xl"}>
      Created at
    </Text>
    {inquiry.createdAt && (
      <Box
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {new Date(inquiry.createdAt).toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata",
        })}
      </Box>
    )}
    <br />
    <Text fontWeight={"semibold"} fontSize={"xl"}>
      Updated at
    </Text>
    {inquiry.modifiedAt && (
      <Box
        padding="10px 20px"
        width="50%"
        bgColor={"#eef1f4"}
        fontSize={"medium"}
      >
        {new Date(inquiry.modifiedAt).toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata",
        })}
      </Box>
    )}
  </Box>
  )
}

export default ViewInquiry