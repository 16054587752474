import React from 'react'
import { AddIcon, DeleteIcon, ViewIcon } from '@chakra-ui/icons'
import { Box, Button, ButtonGroup, Flex, Input, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { BiEditAlt } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
// import DeleteBtn from './DeleteBtn'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import DeleteBtn from './DeleteBtn'
const Page = () => {
    const navigate=useNavigate()
    const url=process.env.REACT_APP_DEV_URL

  return (
    <Box p="4">
        <br/>
        <TableContainer border={"1px solid #161616"} borderRadius={"20px"}>
  <Table variant='simple'  >
    <TableCaption borderTop={"1px solid #161616"} bgColor={"#f9a526"} color={"black"} >There Are 4 Pages</TableCaption>
    <Thead bgColor={"black"}>
      <Tr  >
        <Th  color={"#f9a526"}>#</Th>
        <Th color={"#f9a526"}>Name</Th>
        <Th color={"#f9a526"} >Action</Th>
      </Tr>
    </Thead>
    <Tbody>
            <Tr>
            <Td> 1 </Td>
            <Td>Home</Td>
            <Td>  
                <ButtonGroup>
                <Button leftIcon={<BiEditAlt/>} bgColor={"black"} _hover={{bgColor:"#f9a526",color:"black"}} variant="solid" color="#f9a526" onClick={()=>navigate(`/admin/home`)}>Edit</Button>
                </ButtonGroup>
            </Td>
        </Tr> 
        <Tr>
            <Td> 2 </Td>
            <Td>About</Td>
            <Td>
                <ButtonGroup>
                <Button leftIcon={<BiEditAlt/>} bgColor={"black"} _hover={{bgColor:"#f9a526",color:"black"}} variant="solid" color="#f9a526" onClick={()=>navigate(`/admin/about`)}>Edit</Button>
                </ButtonGroup>
            </Td>
        </Tr>
        <Tr>
            <Td> 3 </Td>
            <Td>Event</Td>
            <Td>  
                <ButtonGroup>
                <Button leftIcon={<BiEditAlt/>} bgColor={"black"} _hover={{bgColor:"#f9a526",color:"black"}} variant="solid" color="#f9a526" onClick={()=>navigate(`/admin/event`)}>Edit</Button>
                </ButtonGroup>
            </Td>
        </Tr>
        <Tr>
            <Td> 4 </Td>
            <Td>Testimonials</Td>
            <Td>  
                <ButtonGroup>
                <Button leftIcon={<BiEditAlt/>} bgColor={"black"} _hover={{bgColor:"#f9a526",color:"black"}} variant="solid" color="#f9a526" onClick={()=>navigate(`/admin/testimonial`)}>Edit</Button>
                </ButtonGroup>
            </Td>
        </Tr>
        <Tr>
            <Td> 5 </Td>
            <Td>Product</Td>
            <Td>  
                <ButtonGroup>
                <Button leftIcon={<BiEditAlt/>} bgColor={"black"} _hover={{bgColor:"#f9a526",color:"black"}} variant="solid" color="#f9a526" onClick={()=>navigate(`/admin/product/text`)}>Edit</Button>
                </ButtonGroup>
            </Td>
        </Tr>
       

    </Tbody>
  </Table>
</TableContainer>

    </Box>
  )
}

export default Page